import {ChangeDetectorRef, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {SwUpdate} from '@angular/service-worker';
import {FeVisitorService} from '../../../fe-common-v2/src/lib/services/fe-visitor.service';
import {EntityCollectionServiceBase} from '@ngrx/data';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fe-visitor';
    loaderVisible: boolean;
    loaderVisible2: boolean;
    service: EntityCollectionServiceBase<any>;
    constructor(
        public translate: TranslateService,
        private cdRef: ChangeDetectorRef,
        private cdRef2: ChangeDetectorRef,
        private router: Router,
        private helper: FeVisitorService
    ) {

        this.helper.loaderVisibilityChange.subscribe((value) => {
            this.loaderVisible = value;
            this.cdRef.detectChanges();
        });
    }

    ngOnInit() {}

    ngAfterViewInit() {
        this.helper.toggleLoaderVisibility(true);
        this.helper.httpProgress().subscribe((status: boolean) => {
            if (status) {
                this.helper.toggleLoaderVisibility(true);
            } else {
                this.helper.toggleLoaderVisibility(false);
            }
        });
        this.service?.clearCache();
    }
}
