import {Component, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import swal from 'sweetalert2';
import {FeVisitorService} from '../../../../../fe-common-v2/src/lib/services/fe-visitor.service';
import {TranslateService} from '@ngx-translate/core';
import {SettingsManagementService} from '../../../../../fe-common-v2/src/lib/services/settings-management.service';
import {HelperService} from '../../../../../fe-procurement/src/app/service/helper.service';
import {visitorHelper} from '../../service/visitorHelper.service';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
    privacyPolicyContent: any;
    privacyData: any;
    currentLanguage: any;
    constructor(
        private visitorAPIService: FeVisitorService,
        public translate: TranslateService,
        private settingManager: SettingsManagementService,
        private helper: visitorHelper,
    ) {
    }

    async ngOnInit(): Promise<void> {
        await this.getVisitorDetails();
        this.currentLanguage = HelperService.getLanguageName();
        this.privacyData = (this.currentLanguage === 'it') ? this.privacyPolicyContent.descriptionIT : this.privacyPolicyContent.descriptionEN;
        this.helper.languageTranslatorChange.subscribe((value) => {
            this.privacyData = value === 'it' ? this.privacyPolicyContent.descriptionIT : this.privacyPolicyContent.descriptionEN;
        });
    }

    async getVisitorDetails(): Promise<any> {
        this.visitorAPIService.toggleLoaderVisibility(true);
        try {
            const res = await this.visitorAPIService.getVisitorData();
            if (res.result) {
                this.privacyPolicyContent = res.data?.privacyPolicyData;
                this.privacyData = (this.currentLanguage === 'it') ? this.privacyPolicyContent.descriptionIT : this.privacyPolicyContent.descriptionEN;
                this.visitorAPIService.toggleLoaderVisibility(false);
            } else {
                swal.fire('', this.translate.instant(res.reason), 'info');
                this.visitorAPIService.toggleLoaderVisibility(false);
            }
        } catch (error) {
            swal.fire('', this.translate.instant(error.message), 'info');
            this.visitorAPIService.toggleLoaderVisibility(false);
        }
    }

}
