
import { Guid } from 'guid-typescript';

export const PERSON_PROPERTY = {
    PP_JOB_SITE: "JobSite",
    PP_CONTRACT_TYPE: "ContractType",
    PP_EMPLOYEE_TYPE: "EmployeeType",  // dirigente, impiegato, etc.
    PP_BADGE_NUMBER: "BadgeNumber",
    PP_WORKING_HOURS: "WorkingHours"
}

export enum RELATIONSHIP_STATE {
    INCOMPLETE_DATA = "IncompleteData",     // persona creata ma mancano delle informazioni obbligatorie per il suo scope (non si può creare l'account)
    MISSING_ACCOUNT = "MissingAccount",     // persona creata con tutte le info ma manca l'account (pronto per la creazione)
    ACTIVE = "Active",                      // persona attiva
    FIXED_END = "FixedEnd",                 // persona a cui è stata fissata una data di fine rapporto futura
    TERMINATED = "Terminated",              // persona con una data di fine rapporto passata
    ACCOUNT_SUSPENDED = "AccountSuspended"  // persona con account sospeso
};

export enum PERSON_TYPOLOGY {
    DIRECT = "Personale diretto",
    INDIRECT = "Personale indiretto",
    PROFESSIONALS = "Professionisti"
};

export enum PERSON_SCOPE {
    EMPLOYEE = "Dipendente",
    PARTNER = "Partner",
    ADVISOR = "Consulente",
    OTHER = "Altro"
};

export enum ACCESS_CONTROL_LEVEL {
    PASSEPARTOUT = "PASSEPARTOUT",
    GROUP = "GROUP",
    SINGLE_PASSAGES = "SINGLE_PASSAGES",
    NONE = "NONE"
};

export class PersonPropertyValue {
    timestamp: Date;
    author: string;
    value: string;

    constructor(value: string) {
        this.timestamp = new Date();
        this.author = '';
        this.value = value;
    }
}

export class PersonProperty {
    name: string;
    values: PersonPropertyValue[];

    constructor(name: string) {
        this.name = name;
        this.values = [];
    }

    getValue() {
        return (this.values && this.values.length > 0) ? this.values[this.values.length].value : '';
    }

    setValue(value: string) {
        this.values.push(new PersonPropertyValue(value));
    }
}

export class PersonHistory {
    id: string;
    a: string; // author
    cf: string[]; // changed fields
    d: Person; // data
    o: string; // operation
    t: Date; // timestamp;
}

export class PeopleDispute {
    key: string;
    date: Date;
    dispute: string;
    note: string;
    author: string;
    notificationDate: Date;

    constructor(date, dispute, note, author, notDate) {
        this.key = Guid.create().toString();
        this.date = date;
        this.dispute = dispute;
        this.note = note;
        this.author = author;
        this.notificationDate = notDate;
    }

    static Empty(): PeopleDispute {
        return new PeopleDispute(null, null, null, null, null);
    }
}

export class Person {
    id: string;
    companyId: string;
    legacyId: string;

    name: string;
    surname: string;
    cf: string;
    birthDay: Date;
    birthPlace: string;
    birthProv: string;

    gender: string;

    address: string;
    city: string;
    prov: string;
    cap: string;

    phone: string;
    email: string;
    personalPhone: string;
    personalEmail: string;

    deleted: boolean;
    profile: string[];
    properties: PersonProperty[];

    site: string;
    direction: string;
    area: string;
    jobTitle: string;
    role: string;
    function: string;

    identificationCode: string; // matricola
    partTimePercentage: number;

    workingHours: Date[];

    badgeId: string;
    restaurantCardId: string;

    dateStart: Date;
    dateEnd: Date;
    relationshipState: RELATIONSHIP_STATE;

    disputes: PeopleDispute[];

    salary: number;
    mbo: number;
    bonus: number;
    contractLevel: string;

    managerId: string;
    managerFullName: string;

    functionalManagerId: string;
    functionalManagerFullName: string;

    lunchOpen: boolean;
    importDate: Date;

    typology: PERSON_TYPOLOGY;

    scope: string;

    enablePlan: boolean;
    enableGreenpass: boolean;
    areaID: any;

    accessControlSystem: string;
    accessControlLevel: ACCESS_CONTROL_LEVEL;
    accessControlGroupsId: string[];
    accessControlPassagesId: string[];
    accessControlQrCode: string;
    accessControlQrCodeValidityEnd: Date;
    checked: boolean;

    enableKeys: boolean;

    favoritesLogGates: String[];

    static filterMatch(data: Person, filter: string): boolean {
        try {
            if (filter) {
                const lowerFilter = filter.toLocaleLowerCase();
                const filterWithoutSpace = lowerFilter.replace(/\s+/g, '');
                const fullNameRef = data.name.toLocaleLowerCase() + data.surname.toLocaleLowerCase();
                const fullName = fullNameRef.replace(/\s+/g, '');
                if (fullName.includes(filterWithoutSpace))
                    return true;
            }
        } catch (ex) {

        }
        return false;
    }

    static Empty(): Person {
        let person = new Person();

        person.workingHours = [null, null, null, null];
        person.disputes = [];

        return person;
    }

    static getFullName(person: Person): string {
        return person.name + ' ' + person.surname;
    }

    getProperty(name: string) {
        if (this.properties) {
            let propertyValue = this.properties.find(p => p.name == name);
            return propertyValue.getValue();
        } else
            return '';
    }

    setProperty(name: string, value: string) {
        if (!this.properties)
            this.properties = [];

        let propertyValue = this.properties.find(p => p.name == name);
        if (!propertyValue)
            propertyValue = new PersonProperty(name);

        propertyValue.setValue(value);
    }
}
