import {Injectable} from '@angular/core';
import {ApiService, buildRequest} from './api';
import {User} from '../models/admin/user';
import {NfsUser} from '../models/api/nfs-user';
import {Person} from '../models/person';
import jwtDecode from 'jwt-decode';
import {BaseResponse} from "./base-response";
import {BehaviorSubject, Observable} from "rxjs";
import {environment} from "../../../../fe-insights-v2/src/environments/environment";
import {HttpClient} from "@angular/common/http";
import {EncryptionService} from "./encryption.service";

class Credentials {
    brandColor: string;
    groupCount: string;
    token: string;
    person: Person;

    constructor(brandColor: string, groupCount: string, token: string, person: Person) {
        this.token = token;
        this.person = person;
        this.brandColor = brandColor;
        this.groupCount = groupCount;
    }
}

class LoginResponse {
    result: boolean;
    user: {
        brandColor: string
        groupCount: string
        token: string;
        person: Person;
    };
    reason: string;
    resetPassword?: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserManagementService {
    baseUrl: string = environment.api_host;
  userListpagination = new BehaviorSubject({ scope: '', search: '', page: 1, limit: "10", sortBy: '', sortKey: '' })
    //
    // API documentation: https://agos.nfsonline.net/WorkspaceAPI/Help
    //
    private userCredentials: Credentials;
    private authorizationToken: Credentials;
    companyId: any;

    constructor(private apiService: ApiService,
                private http: HttpClient,
                private security:EncryptionService) {
        this.loadCredentials();
        let str : any = this.security.decryptionAES(localStorage.getItem("credentials"))
        const credentials = str;
        if (credentials) {
            const authUser: any = JSON.parse(credentials);
            // this.token = authUser.token;
            this.companyId = authUser.person.companyId;
            // console.log('token', this.token);
            // console.log('id', this.companyId);
        }
    }

    initialize() {
        return new Promise(async (resolve, reject) => {
            if (!this.loadCredentials()) {
                this.userCredentials = null;
            }
            resolve(null);
        });
    }

    async login(username: string, password: string, isConsole: boolean, isLogin: boolean = true) {
        try {
            let bodyRequest: any = {
                user: username,
                password: password,
                isConsole: isConsole
            };
            let res = null;
            // if (isLogin) {

            res = await this.apiService.post<LoginResponse>(this.apiService.API.ACCOUNT.LOGIN, bodyRequest).toPromise();
                if (res.result){
                    this.authorizationToken = res.user.otp;
                    this.security.setDataToLocalStorage('reset-otp', JSON.stringify(this.authorizationToken));
                    this.security.setDataToLocalStorage('reset-password', JSON.stringify(res.resetPassword));
                    // localStorage.setItem('reset-otp', JSON.stringify(this.authorizationToken));
                    // localStorage.setItem('reset-password', JSON.stringify(res.resetPassword));
                }else {
                    return {
                        result: false,
                        reason: res.reason
                    };
                }

            // } else {
            //     //bodyRequest.otp = this.authorizationToken;
            //     bodyRequest = {
            //         password: username,
            //         confPassword: password,
            //         otp: this.authorizationToken
            //     };
            //     res = await this.apiService.post<LoginResponse>(this.apiService.API.ACCOUNT.LOGINRESETPASSWORD, bodyRequest).toPromise();
            //     // localStorage.setItem('reset-otp', JSON.stringify(this.authorizationToken));
            //     this.security.setDataToLocalStorage('reset-otp', JSON.stringify(this.authorizationToken));
            // }
            if (res.result && res.resetPassword)
                this.saveCredentials(res.user.brandColor, res.user.groupCount, res.user.token, res.user.person);
            return {
                result: res.result,
                resetPassword: res.resetPassword,
                resetPasswordOnFirstLogin: res.user.resetPasswordOnFirstLogin,
                sendOTP: res.user.sendOTP,
                reason: res.reason
            };
        } catch (ex) {
            console.log('UserManagementService.login: exception - ', ex);
            return {
                result: false,
                reason: 'You don\'t have access to this system'
            };
        }
    }

    async forgotPassword(email: string) {
        try {
            let bodyRequest = {
                email: email
            };
            let res = await this.apiService.post<any>(this.apiService.API.ACCOUNT.ForgotPassword, bodyRequest).toPromise();

            return {
                result: res.result,
                reason: res.reason
            };
        } catch (ex) {
            console.log('UserManagementService.login: exception - ', ex);
            return {
                result: false,
                reason: 'Internal error'
            };
        }
    }

    async resetPassword(data) {
        try {
            let res = await this.apiService.post<any>(this.apiService.API.ACCOUNT.ResetPassword, data).toPromise();

            return {
                result: res.result,
                reason: res.reason
            };
        } catch (ex) {
            console.log('UserManagementService.login: exception - ', ex);
            return {
                result: false,
                reason: 'Internal error'
            };
        }
    }

    async resetPasswordLogin(data) {
        try {
            let res = await this.apiService.post<any>(this.apiService.API.ACCOUNT.LOGINRESETPASSWORD, data).toPromise();

            return {
                result: res.result,
                reason: res.reason
            };
        } catch (ex) {
            console.log('UserManagementService.login: exception - ', ex);
            return {
                result: false,
                reason: 'Internal error'
            };
        }
    }


    async touchPointAuth(touchPointId: string) {
        try {
            let url = buildRequest(this.apiService.API.BE.TOUCHPOINT_AUTH,
                {
                    ':id': touchPointId
                });

            let res = await this.apiService.get<LoginResponse>(url).toPromise();

            if (res.result)
                this.saveCredentials(res.user.brandColor, res.user.groupCount, res.user.token, res.user.person);

            return {
                result: res.result,
                reason: res.reason
            };
        } catch (ex) {
            console.log('UserManagementService.touchPointAuth: exception - ', ex);
            return {
                result: false,
                reason: 'Internal error'
            };
        }
    }

    invalidateToken() {
        this.userCredentials = null;
        localStorage.removeItem("credentials");
    }

    getToken(): string {
        this.loadCredentials()
        return this.userCredentials ? this.userCredentials.token : null;
    }

    getTokenDecoded(): any {
        return this.userCredentials ? jwtDecode(this.userCredentials.token) : null;
    }

    getAccount(): Person {
        return this.userCredentials ? this.userCredentials.person : null;
    }

    getBrandColor(): string {
        return this.userCredentials ? this.userCredentials.brandColor : null;
    }

    groupCount(): string {
        return this.userCredentials ? this.userCredentials.groupCount : null;
    }

    logout() {
        localStorage.removeItem("credentials");
    }

    private loadCredentials(): boolean {
        let str = this.security.decryptionAES(localStorage.getItem("credentials"))
        let credentials: Credentials = JSON.parse(str);
        if (credentials == null)
            return false;

        this.userCredentials = credentials;

        return true;
    }

    getnotifications(data: any): Observable<any> {
        return this.http.post<any>(this.baseUrl + '/v2/mqs-recruiting/notification-listing', data);
    }

    // async getNotification(data: any): Promise<BaseResponse> {
    //   return this.apiService.post<BaseResponse>(this.apiService.API.BE.BELL_NOTIFICATION_LIST, {isUnRead : false}).toPromise();
    // }

    getnotificationsList(data: any): Observable<any> {
        return this.http.post<any>(this.baseUrl + '/v2/mqs-recruiting/push-notification/list', data);
    }

    async pushNotification(data: any): Promise<BaseResponse> {
        return this.apiService.post<BaseResponse>(this.apiService.API.BE.PUSH_NOTIFICATION, data).toPromise();
    }

    async deletepushNotification(data: any): Promise<BaseResponse> {
        return this.apiService.post<BaseResponse>(this.apiService.API.BE.DELETE_PUSH_NOTIFICATION, data).toPromise();
    }

    getnotificationsRead(data: any): Observable<any> {
        return this.http.post<any>(this.baseUrl + '/v2/mqs-recruiting/notification/read-unread', data);
    }

    getProcurementNotificationsRead(data: any): Observable<any> {
        return this.http.post<any>(this.baseUrl + '/v2/mqs-procurement/notification/read', data);
    }

    private saveCredentials(brandColor: string, groupCount: string, token: string, person: Person) {
        let credentials = new Credentials(brandColor, groupCount, token, person);

        this.userCredentials = credentials;
        localStorage.setItem("credentials", this.security.encryptionAES(JSON.stringify(credentials)));
    }

    dailyStatistics(date: any, data: any): Observable<any>{
        return this.http.post<any>(this.baseUrl + '/v2/user-manager/daily/statistics/' + date + '/' + date, data);
    }

    sitesActivityStatistics(date: any, data: any): Observable<any>{
        return this.http.post<any>(this.baseUrl + '/v2/user-manager/superwiser-dashborad/statistics/site/activity/' + date, data);
    }

    globalActivityStatistics(date: any, data: any): Observable<any>{
        return this.http.post<any>(this.baseUrl + '/v2/user-manager/global/statistics/activity/' + date, data);
    }

    supervisorDashboardSafety(date: any, data: any): Observable<any>{
        return this.http.post<any>(this.baseUrl + '/v2/user-manager/supervisor-dashboard/safety/' + date,  data);
    }

    parkingStatistics(data: any): Observable<any>{
        return this.http.post<any>(this.baseUrl + '/v2/booking/parking/statistics', data);
    }

    getPlanDetails(userId: any, year: any, month: any): Observable<any>{
        return this.http.get<any>(this.baseUrl + '/v2/user-manager/plan/all/' + userId + '/' + year + '/' + month);
    }

    downloadEmployee(data: any): Observable<any>{
        return this.http.post<any>(this.baseUrl + '/v2/user-manager/supervisor-dashboard/download/single-user-statistics', data);
    }

    getWeeklyPlanCount(date: any, data: any): Observable<any>{
        return this.http.post<any>(this.baseUrl + '/v2/user-manager/supervisor-dashboard/weekly-plan-count/' + date, data);
    }
    getMyAnalyticsStatistic(data: any): Observable<any>{
        return this.http.post<any>(this.baseUrl + '/v2/user-manager/statistics', data);
    }

    getMyAnalyticsStatisticDetails(data: any): Observable<any>{
        return this.http.post<any>(this.baseUrl + '/v2/user-manager/statistics/detail-new', data);
    }

    getMyAnalyticsStatisticAggregate(data: any): Observable<any>{
        return this.http.post<any>(this.baseUrl + '/v2/user-manager/statistics/aggregate', data);
    }

    downloadStatisticsPlan(data: any): Observable<any>{
        return this.http.post<any>(this.baseUrl + '/v2/user-manager/supervisor-dashboard/download/statistics/plan', data);
    }

    getPeopleListMultiple(data: any): Observable<any>{
        return this.http.post<any>(this.baseUrl + '/v2/user-manager/company/area/people/list/multiple', data);
    }

    async getOfficePresenseChart(data: any): Promise<any>{
        return this.http.post<any>(this.baseUrl + '/v2/user-manager/supervisor-dashboard/office-presense-chart', data).toPromise();;
    }

    getMyAnalyticsCompliancesDetails(data: any): Observable<any>{
        return this.http.post<any>(this.baseUrl + '/v2/user-manager/statistics/compliance', data);
    }
    getMyAnalyticsUserStatistics(data: any): Observable<any>{
        return this.http.post<any>(this.baseUrl + '/v2/user-manager/statistics/users', data);
    }
    getCompliancesRule(data: any): Observable<any>{
        return this.http.post<any>(this.baseUrl + '/v2/user-manager/company/compliance/list-by-multiple-scope', data);
    }
    getRuleData(data: any){
        return this.http.post<any>(this.baseUrl + '/v2/user-manager/company/compliance/get-compliance-by-name', data);
    }
}
