import { Injectable } from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../../../fe-visitor/src/environments/environment';
import {FormControl} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FeVisitorService {
    baseUrl: string = environment.api_host;
    isLoaderVisible = false;
    loaderVisibilityChange: Subject<boolean> = new Subject<boolean>();
    private httpLoading$ = new ReplaySubject<boolean>(1);
  constructor(private http: HttpClient) { }

    static noWhitespaceValidator(control: FormControl): any {
        const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : {required: true};
    }

    getVisitorDetail(): Promise<any> {
        return this.http.get(this.baseUrl + '/v2/sustainability/visitor/getTransportData').toPromise();
    }

    addVisitor(data: any): Promise<any> {
        return this.http.post(this.baseUrl + '/v2/sustainability/visitor/daily-impact', data).toPromise();
    }

    getVisitorData(): Promise<any> {
        return this.http.get(this.baseUrl + '/v2/visitor/settings/configuration').toPromise();
    }

    toggleLoaderVisibility(isSidebarVisible: boolean): void {
        this.isLoaderVisible = isSidebarVisible;
        this.loaderVisibilityChange.next(this.isLoaderVisible);
    }

    httpProgress(): Observable<boolean> {
        return this.httpLoading$.asObservable();
    }
}
