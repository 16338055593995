import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, retry, tap} from 'rxjs/operators';
import {SessionManagementService} from './session-management.service';
import {Configurations} from '../configurations';
import {CommonService} from './common.service';

export interface ApiParam {
    paramname: string;
    paramvalue: string;
}

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    public API = {
        // AUTH
        AUTH: {
            LOGIN: "/auth/login"
        },

        ACCOUNT: {
            LOGIN: "/v2/user-manager/account/login",
            LOGINRESETPASSWORD: "/v2/user-manager/account/login-reset-password",
            ForgotPassword: "/v2/user-manager/account/forgot-password",
            ResetPassword: "/v2/user-manager/account/reset-password",
        },

        // EMC
        EMC: {
            LOGIN: '/v2/user-manager/account/login/event-attendee',
            LOGINRESETPASSWORD: "/v2/user-manager/account/login-reset-password",
            ForgotPassword: "/v2/user-manager/account/forgot-password/event-attendee",
            ResetPassword: "/v2/user-manager/account/reset-password",
            EMC_MINIAPP_EVENT_LIST: "/v2/event-manager/event/list/mini-app",
            EMC_MINIAPP_EVENT_CHECK_IN_OUT_EDIT: "/v2/event-manager/event/edit-checkIn-out",
            EMC_MINIAPP_EVENT_VIEW: "/v2/event-manager/event/view/mini-app",
            EMC_MINIAPP_EDIT_CREDITS: "/v2/event-manager/event/add",
            EMC_MINIAPP_EDIT_ATTENDEES_CREDITS: "/v2/event-manager/event/edit-event-credit",
            EMC_MINIAPP_ADD_CHECKPOINTS: "/v2/event-manager/event/add-checkpoint",
            EMC_MINIAPP_CHANGE_INVITATION_STATUS: "/v2/event-manager/event/change-invitation-status",
            EMC_MINIAPP_REMAINING_CHECKPOINTS: "/v2/event-manager/event/remaining-checkpoint",
            EMC_MINIAPP_GET_LATEST_UPDATED_TIME: "/v2/event-manager/event/get-last-checkIn-out",
            EMC_MINIAPP_LIST_OF_EVENT_ACTIVITY_LOG: "/v2/event-manager/event/MiniAppAttendeesActivityLogList",
            EMC_MINIAPP_UPLOAD_CHECKPOINT_LIST: "/v2/event-manager/event/import-checkpoint",
            logOut:"/v2/user-manager/account/logout/event-attendee"
        },

        // ADMIN
        ADMIN: {
            // USER MANAGEMENT API
            USER_REGISTER: "/user/register",
            USER_LIST: "/user/list/:id",
            USER_DELETE: "/user/:id",
            USER_INFO: "/user/:id",
            CHANGE_PASSWORD: "/user/change-password",
            USER_UPDATE: "/user",
            UPLOAD_USERS: "/users/upload",
            UPLOAD_USERS_LIST: "/users/uploadlist",
            UPLOAD_SAFETY_USERS: "/users/upload/safetyroles",
            USERS: "/users",
            USERS_COUNT: "/users/count"
        },

        // BE
        BE: {
            // BOOK-REQUEST-MANAGEMENT
            USER_REQUEST_BOOK: "/v2/user-request-book",
            TRACK_USER_PLAN_ACTIVITY: "/v2/user-plan-activity",
            GET_USER_REQUEST_BOOK: "/v2/get-user-request-book/:site/:id",
            GET_USER_DAILY_INFO: "/v2/user-daily-info/:id",
            TRACK_USER_ACTIVITY: "/v2/user-activity/track",

            GET_USER_REQUEST_BOOK_BY_DATE: "/v2/get-user-request-book/:id/date/:date",
            DELETE_USER_PLANS_BY_DATE_RANGE: "/v2/update/user-plans/:siteKey/:startDate/:endDate",

            // USER-ACTIVITY
            GET_USER_ACTIVITY: "/v2/user-activity/:id",
            GET_USER_ACTIVITY_BY_DATE: "/v2/user-activity/:id/:date",
            DELETE_USER_ACTIVITY: "/v2/user-activity/:id/:date",

            // USER-AREAS
            GET_USER_AREAS: "/v2/user/areas/:siteKey",
            GET_DEPARTMENT_USERS: "/v2/department/:departmentId/users",

            // USER HOME DATA
            GET_USER_HOME_DATA: "/v2/home/:userId",
            GET_USER_HOME_TILES: "/v2/home/tiles/:userId/:lang",

            GET_USER_HIERARCHY: "/v2/user/hierarchy/:userId",
            GET_USER_DEPARTMENT: "/v2/user/department/:userId",
            USER_HAS_OFFICE_RESERVATION: "/v2/user/office/reservation/:userId/:siteKey",

            // USER CALENDAR DATA
            GET_USER_CALENDAR_DATA: "/v2/user/calendar/:siteKey/:userId/:year/:month",
            GET_USER_PLAN_DATA: "/v2/user/plan/:year/:month",

            // SITE MANAGEMENT API
            SITE_LIST: "/v2/site-list",
            SITE_KEY: "/v2/site/:key",
            SITE: "/v2/site",
            UPLOAD_SITES: "/v2/sites/upload",

            // SITE-DAILY-STATUS
            SITE_DAILY_STATUS: "/v2/site-daily-status",
            GET_SITE_DAILY_STATUS: "/v2/site-daily-status/:siteKey",
            UPDATE_SITE_DAILY_STATUS_CAP: "/v2/site-daily-status/capacity/:siteKey/:date",

            // SITE CAPACITY TIMEFRAMES
            SITE_CAPACITY_TIMEFRAME: "/v2/site-capacity-rages/:siteKey",

            // USER-DAILY-ACCESS
            USER_DAILY_ACCESS: "/v2/user-daily-access",
            GET_USER_DAILY_ACCESS: "/v2/user-daily-access/:siteKey/:userId",

            // SITE STATISTICS
            SITE_DAILY_STATISTICS: "/v2/site/statistics/:siteKey/:date",
            USER_DAILY_ACTIVITIES: "/v2/users/statistics/activities/:date",
            SITE_PRESENCE_STATS: "/v2/site/statistics/presence/:siteKey/:startDate/:endDate",

            // NEWS
            NEWS_UPLOAD: "/v2/news/upload",
            NEWS_DOCLIST: "/v2/news/:userId",
            NEWS_DOC: "/v2/news/:id",
            NEWS_ACTION: "/v2/news/action/:id",
            NEWS_DOWNLOAD: "/v2/news/download/:id",

            // USER/TEAM/DEPARTMENT STATISTICS
            USER_STATISTICS: "/v2/user/statistics/:period/:siteKey/:userId",
            TEAM_STATISTICS: "/v2/team/statistics/:period/:siteKey/:userId",
            DEPARTMENT_STATISTICS: "/v2/department/statistics/:period/:siteKey/:userId",
            CSV_TEAM_STATISTICS: "/v2/csv/team/statistics/:userId/:dateFrom/:dateTo",
            USER_STATISTICS_DAILY: "/v2/statistics/:userId/:dateFrom/:dateTo",
            TEAM_WEEKLY_STATISTICS: "/v2/team/weekly/statistics/:siteKey/:userId/:dateFrom/:dateTo",
            DEPARTMENT_WEEKLY_STATISTICS: "/v2/department/weekly/statistics/:siteKey/:userId/:dateFrom/:dateTo",
            DEPARTMENT_WEEKLY_STATISTICS_BY_AREA: "/v2/department/weekly/statistics/:departmentId/:dateFrom/:dateTo",
            GLOBAL_STAT_ACTIVITY: "/v2/global/statistics/activity/:date",
            CSV_DEPARTMENT_STATISTICS_DAILY: "/v2/csv/department/statistics/:departmentId/:dateFrom/:dateTo",
            USERS_DAILY_STATISTICS: "/v2/users/daily/statistics/:dateFrom/:dateTo",
            USERS_DAILY_STATISTICS_BY_SITE: "/v2/users/daily/statistics/:siteKey/:dateFrom/:dateTo",
            CSV_SINGLE_USER_STATISTICS_DAILY: "/v2/csv/user/statistics/:userId/:dateFrom/:dateTo",
            SINGLE_USER_STATISTICS_DAILY: "/v2/single/user/statistics/:userId/:dateFrom/:dateTo",
            SAFETY_USERS_DAILY: "/v2/safety/users/daily/:dateFrom/:dateTo",
            BOARD_DIRECTORS_USER_STATS: "/v2/board/directors/statistics/:dateFrom/:dateTo",
            APM_SITE_STATS: "/v2/apm/statistics/:dateFrom/:dateTo",
            APM_SITE_STATS_CSV: "/v2/apm/statistics/csv/:dateFrom/:dateTo",

            // USER HEALTH
            USER_TEAM_HEALTH: "/v2/user/team/health",

            // LOGGING EVENT
            LOG_EVENT: "/v2/log/event",
            GET_LOG_EVENTS: "/v2/log/events",

            // SAFETY
            GET_SAFETY: "/v2/safety/:siteKey",

            // SCHEDULER
            GET_SCHEDULE_EVENTS: "/v2/scheduler/events",
            ADD_SCHEDULE_EVENT: "/v2/scheduler/event",
            UPDATE_SCHEDULE_EVENTS: "/v2/scheduler/event/:id",
            DELETE_SCHEDULE_EVENTS: "/v2/scheduler/event/:id",
            SCHEDULER_EMAIL_CONFIGURATION: "/v2/scheduler/setting/email-configuration/add-edit",
            GET_SCHEDULER_EMAIL_CONFIGURATION: "/v2/scheduler/setting/email-configuration",
            GET_SCHEDULER_EMAIL_LIST: "/v2/scheduler/list-email-template",
            SCHEDULER_UPDATE_EMAIL_TEMPLATE: "/v2/scheduler/update-email-template",

            // WORKFLOW
            GET_WORKFLOW_TEMPLATES: "/v2/workflow/templates",
            UPDATE_WORKFLOW_TEMPLATE: "/v2/workflow/update/template",
            GET_WORKFLOW_INSTANCES: "/v2/workflow/instances",
            GET_ALL_WORKFLOW_INSTANCES: "/v2/workflow/instances/all",
            GET_USER_WORKFLOW_INSTANCES: "/v2/workflow/user/instances/:id",
            CREATE_WORKFLOW_INSTANCE: "/v2/workflow/create/instance/:userId/:templateId/:startAt",
            DELETE_WORKFLOW_INSTANCE: "/v2/workflow/delete/instance/:workflowId",
            EXEC_WORKFLOW_ACTION: "/v2/workflow/:workflowId/user/:userId/execute/:actionId",
            UPLOAD_WORKFLOW_INSTANCE: "/v2/workflow/instance/upload",

            // PROFILE-SETTINGS
            SETTINGS: "/v2/settings",
            SETTINGS_BY_ID: "/v2/settings/:id",
            EMAIL_CONFIGURATION: "/v2/user-manager/company",

            // ACTION-TILES
            ACTION_TILES: "/v2/action-tiles",
            UPDATE_ACTION_TILE: "/v2/action-tile",

            // RESERVATION
            GET_RESERVATION_SITE: "/v2/reservation/sites",
            UPDATE_RESERVATION_SITE: "/v2/reservation/site",
            DELETE_RESERVATION_SITE: "/v2/reservation/site/:id",
            GET_RESERVATION_ITEMS: "/v2/reservation/site/:id/items",
            UPDATE_RESERVATION_ITEM: "/v2/reservation/site/item",
            DELETE_RESERVATION_ITEM: "/v2/reservation/item/:id",
            USER_RESERVATION: "/v2/user/reservation/item/:id/:userId",
            USER_MANUAL_RESERVATION: "/v2/user/reservation/manual/:siteId/:date/:userId",
            USER_SITE_RESERVATION: "/v2/user/reservation/site/:id/:userId",
            CHECK_USER_SITE_RESERVATION: "/v2/check/user/reservation/site/:userId",
            CHECK_USER_ITEM_RESERVATION: "/v2/check/user/reservation/item/:userId",
            CSV_USER_SITE_PRERESERVATION: "/v2/csv/user/pre-reservation/site",
            CSV_USER_SITE_RESERVATION: "/v2/csv/user/reservation/site/:id",
            SITE_AVAILABLE: "/v2/reservation/site/available/:id",
            SEND_AVAILABILITY_MAIL: "/v2/people-caring/reservation/site/available/:id",
            PRE_RESERVATION_DELETE: "/v2/people-caring/pre-reservation/delete",

            // BOOKABLE RESOURCES
            GET_BOOKABLE_RESOURCE_TYPES: "/v2/booking/resource/types",
            ACTION_BOOKABLE_RESOURCE_TYPE: "/v2/booking/resource/type",
            DELETE_BOOKABLE_RESOURCE_TYPE: "/v2/booking/resource/type/:id",
            GET_BOOKABLE_RESOURCES: "/v2/booking/resources/:id",
            GET_BOOKABLE_RESOURCES_DETAILS: "/v2/booking/resource/view",
            ACTION_BOOKABLE_RESOURCE: "/v2/booking/resource/view",
            DELETE_BOOKABLE_RESOURCE: "/v2/booking/resource/:id",
            DELETE_BOOKABLE_MULTIPLE_RESOURCE: "/v2/booking/resource/delete",
            BOOKABLE_RESOURCE_UPLOAD_CSV: "/v2/booking/resource/upload/:type",
            GET_RESOURCE_STATUS: "/v2/booking/resource/status/:date/:resourceType",
            RESERVE_RESOURCE_SLOT: "/v2/booking/reserve/:resourceType/resource/:id/slot/:slot/date/:date",
            RESERVE_USER_RESOURCE_SLOT: "/v2/booking/reserve/:resourceType/resource/:id/slot/:slot/date/:date/user/:userId",
            RESOURCE_RESERVATION_STATUS: "/v2/booking/resource/reservation/status/:date/timeSlots/:resourceId",
            RESOURCE_AVAILABLE: "/v2/booking/resource/type/available/:resourceType",
            RESOURCE_SEARCH_AVAILABILITY: "/v2/booking/resource/search/availability",
            RESERVE_RESOURCE_TIMEFRAME: "/v2/booking/reserve/:resourceType/resource/:resourceId/timeframe",
            GET_USER_RESOURCE_RESERVATIONS: "/v2/booking/resource/reservations/:startDate/:endDate",
            RESOURCE_RESERVATION_TIMEFRAME: "/v2/booking/resource/reservations/:reservationId/:timeframeId",
            RESOURCE_RESERVATION_TIMEFRAME_DA: "/v2/booking/resource/reservations/:reservationId/:timeframeId/da",
            USER_RESOURCE_INTERACTION: "/v2/booking/interact/:resourceId",
            USER_RESOURCE_ACTION: "/v2/booking/resource/:resourceId/action",
            CURRENT_RESOURCE_STATUS: "/v2/booking/resource/:resourceId/status/:date",
            GET_BOOKABLE_RESOURCE_TYPE: "/v2/booking/resource/type/:id",
            GET_GROUP_BOOKABLE_RESOURCES: "/v2/booking/resources/:type/group/:groupId",
            GET_RESOURCE_BY_CODE: "/v2/booking/resource/:code",
            RESOURCE_TYPE_LIST: "/v2/booking/resource/list-resource-type",
            RESOURCE_TYPE_DELETE: "/v2/booking/resource/delete-resource-type",
            RESOURCE_TYPE_ADD_EDIT: "/v2/booking/resource/add-edit-resource-type",
            RESOURCE_TYPE_GET_BY_ID: "/v2/booking/resource/view-resource-type",
            RESOURCE_BOOKING_LAYOUT_LIST: "/v2/booking/layout/list",
            RESOURCE_PARKING_RESERVATION: "/v2/booking/resources/:typeId/reservations/:startDate/:endDate",
            RESOURCE_BOOK_RESERVATION: "/v2/booking/parking/book/:date/:id",
            RESOURCE_PARKING_BOOK_RESERVATION:"/v2/booking/parking/book/:date/:userId",
            RESOURCE_ROOM_BOOK_EDIT :"/v2/booking/reservation/edit/:resourceId/timeframe/da",
            RESOUCE_ROOMEDIT_LIKE_MOBILE:"/v2/booking/resource/reservations/:reservationId/:timeframeId",
            RESOURCE_REMOVE_RESERVATION: "/v2/booking/parking/book/:id",
            RESOURCE_BOOK_ECHARGER: "/v2/booking/reserve/timeSlots/resource/:resourceId/slot/:slotId/date/:date/user/:userId",
            UPDATE_SETTING:"/v2/booking/setting/update",
            EXPORT_WAITING_LIST:"/v2/booking/parking/csv/:resourceGroupId",
            // UPLOAD RESOURCE
            UPLOAD_RESOURCE: '/v2/booking/parking/upload/:resourceGroupId',
            UPDATE_RESOURCE: '/v2/booking/parking/prebook/:resourceGroupId',
            DOWNLOAD_TIMESLOT_RESOURCE: '/v2/booking/resources/:typeId/report/:startDate/:endDate',
            RESOURCE_RESERVATION_GROUP: "/v2/booking/calendar/resource-group/reservations/:resourceGroupId/:startDate/:endDate",
            RESOURCE_BOOKING_FILTERS: "/v2/booking/resources/filters/:typeId",
            RESOURCE_BOOKING_AVAILABILITY: "/v2/booking/resource/search/availability",
            RESOURCE_BOOKING_TIMEFRAME: "/v2/booking/reserve/timeAndDay/resource/:resourceId/timeframe",
            RECURRENCE_BOOKING_DELETE : '/v2/booking/recurrence/delete',
            RECURRENCE_DETAILS:"/v2/booking/recurrence/details",
            BOOKING_DETAILS:"/v2/booking/details",
            BOOKING_FILTER_DATA:"/v2/booking/resource-name/list",



    // TOUCHPOINT
            TOUCHPOINT_KEEP_ALIVE: "/v2/touchpoint/keep-alive",
            TOUCHPOINT_KEEP_ALIVE_LEGACY: "/v2/touchpoint/keep-alive/legacy",
            TOUCHPOINT_LIST: "/v2/touchpoint/list",
            TOUCHPOINT_SETTINGS: "/v2/touchpoint/settings",
            TOUCHPOINT_PAIR: "/v2/touchpoint/pair",
            TOUCHPOINT_REGISTER: "/v2/touchpoint/register",
            TOUCHPOINT_CHECK_PAIR: "/v2/touchpoint/check-pair",
            TOUCHPOINT_DELETE: "/v2/touchpoint/:id",
            TOUCHPOINT_AUTH: "/v2/touchpoint/auth/:id",
            TOUCHPOINT_UNLOCK_PAIR: "/v2/touchpoint/unlock/:id",
            TOUCHPOINT_EDIT: '/v2/touchpoint/edit/:id',
            GET_EMAIL_CONFIGURATION: '/v2/touchpoint/email-configuration/update',
            GET_TOUCHPOINT_LOG: '/v2/touchpoint/get-activity-logs',


            // SURVEY
            GET_SURVEY: "/v2/survey/:id",
            GET_SURVEY_RESULTS: "/v2/survey/results/:id",
            USER_SURVEY_ACTION: "/v2/survey/:id/user/action/:action",
            SUBMIT_SURVEY: "/v2/survey/:id/submit",
            SURVEY_USER_ENABLED: "/v2/survey/:id/user/enabled",
            GET_SURVEY_CSV: "/v2/survey/results/csv/:id",
            ADD_SURVEY: "/v2/mqs-quiz/quiz-create",
            VIEW_SURVEY: "/v2/mqs-quiz/quiz/view",
            // MATRIX
            MATRIX_LIST: "/v2/mqs-quiz/metric/list",
            ADD_MATRIX: "/v2/mqs-quiz/metric/save",
            DELETE_MATRIX: "/v2/mqs-quiz/metric/delete",
            VIEW_MATRIX: "/v2/mqs-quiz/metric/view",
            //QUIZ
            QUIZ_LISTING: '/v2/mqs-questions/list',
            QUIZ_QUESTION_DELETE: '/v2/mqs-questions/delete',
            VIEW_QUIZ_DEATILS: "/v2/mqs-questions/view",
            ADD_QUESTION: '/v2/mqs-questions/create',
            // ADD_QUESTION: '/v2/mqs-quiz/quiz-create',


            // SUSTAINABILITY
            SUSTAINABILITY_TRANS_TYPE: "/v2/sustainability/transport-type",
            GET_SUSTAINABILITY_TRANS_TYPES: "/v2/sustainability/transport-types",
            DEL_SUSTAINABILITY_TRANS_TYPE: "/v2/sustainability/delete/transport-type",
            GET_SUSTAINABILITY_TRANS_TYPES_DETAIL: "/v2/sustainability/transport-type/:id",
            SUSTAINABILITY_TRANS_NOTES: "/v2/sustainability/transport-note",
            GET_SUSTAINABILITY_TRANS_NOTES: "/v2/sustainability/transport-notes",
            DEL_SUSTAINABILITY_TRANS_NOTES: "/v2/sustainability/delete/transport-note",
            GET_SUSTAINABILITY_TRANS_NOTES_DETAIL: "/v2/sustainability/transport-note/:id",
            USER_HABIT: "/v2/sustainability/user/habit",
            GET_ALL_USERS_HABITS: "/v2/sustainability/all/users/habits",
            GET_ALL_USERS_HABITS_STATS: "/v2/sustainability/user/habit/statistics",
            GET_VIEW_SUMMARY: "/v2/sustainability/view/summary",
            TOTAL_TRANSPORTS_IMPACT_STATS: "/v2/sustainability/total/transports/impact/statistics/:startDate/:endDate",
            OVERALL_STAT_DETAILS: "/v2/sustainability/overall/statistics/details/:startDate/:endDate",
            VISITOR_TRANSPORTS_IMPACT_STATS: "/v2/sustainability/total/transports/impact/visitors/statistics/:startDate/:endDate",
            VISITOR_TRANSPORTS_IMPACT_STATS_CHART: "/v2/sustainability/total/transports/impact/visitors/statistics/chart/:startDate/:endDate",
            USERS_IMPACT_CSV: "/v2/sustainability/users/impact/csv/:startDate/:endDate/:userType",
            USERS_IMPACT_CSV_BY_AREA: "/v2/sustainability/users/impact/csv",
            USERS_IMPACT_XLSX_BY_AREA: "/v2/sustainability/users/impact/xlsx",
            USERS_ENGAGEMENT_CSV: "/v2/sustainability/users/engagement/csv",
            VISITORS_IMPACT_CSV: "/v2/sustainability/visitors/impact/csv/:startDate/:endDate",
            VISITORS_IMPACT_XLSX: "/v2/sustainability/visitors/impact/xlsx/:startDate/:endDate",
            TRANS_IMPACT_CSV: "/v2/sustainability/transport/impact/csv/:startDate/:endDate/:userType",
            TRANS_IMPACT_CSV_BY_AREA: "/v2/sustainability/transport/impact/csv",
            TRANS_IMPACT_XLSX_BY_AREA: "/v2/sustainability/transport/impact/xlsx",
            // VISITORS
            GET_VISITORS_DASHBOARD: "/v2/visitors/dashboard",
            GET_VISITORS_CATEGORY: "/v2/visitors/category/list",
            GET_SCOPE_CATEGORY_LIST: "/v2/sustainability/scope-category-list",
            GET_VISITORS_CATEGORY_DETAIL: "/v2/visitors/category/edit/:id",
            ADD_VISITORS_CATEGORY: "/v2/visitors/category/add",
            DEL_VISITORS_CATEGORY: "/v2/visitors/category/delete",
            GET_VISITORS_SETTINGS:"/v2/visitor/settings",
            ADD_VISITORS_SETTINGS:"/v2/visitors/add-configuration",
            GET_VISITOR_EMAIL_CONFIGURATION: "/v2/visitor/settings/email-configuration",
            ADD_VISITOR_EMAIL_CONFIGURATION: "/v2/visitors/add-email-configuration",
            GET_VISITOR_REASON:"/v2/visitor/get-transport-reason",

            // VISITOR USERS
            ADD_EDIT_VISITOR_USER: "/v2/visitor/add",
            GET_VISITOR_USER_DETAIL: "/v2/visitor/edit/:id",
            GET_LIST_OF_VISITOR_USER: "/v2/visitors",
            CHANGE_BADGE_STATUS_VISITOR_USER: "/v2/visitor/change/badge-status",
            GET_VISITOR_USER_INVITE: "/v2/visitors/invite/:id",
            GET_VISITOR_WIFI_PASSWORD: "/v2/visitors/wifi/password",
            GET_VISITOR_CMS_LIST: "/v2/visitor/settings/cms/list",
            GET_VISITOR_CMS_DETAILS: "/v2/visitor/settings/cms/add-edit",

            GET_EMPLOYEE_TRANSPORT_HABITS: "/v2/sustainability/user/habit/statistics",
            GET_TRANSPORT_HABITS: "/v2/sustainability/user/habit/statistics/:userType",
            USER_AREA_STATS: "/v2/sustainability/user/area/statistics/:startDate/:endDate/:userType",
            USER_AREA_STATS_BY_AREA: "/v2/sustainability/user/area/statistics",
            SUSTAINABILITY_MOBILE_FIELDS_SAVE: "/v2/sustainability/mobile-app-fields-schema",

            // PERSON GROUPS/PROFILES
            GET_GROUPS_BY_COMPANY: "/v2/user-manager/group/:companyId",
            GET_GROUPS_LIST_BY_COMPANY_: "/v2/user-manager/groupList/:companyId",
            ADD_UPDATE_GROUP: "/v2/user-manager/group",
            DELETE_GROUP: "/v2/user-manager/group/:id",
            UPDATE_GROUP_PERSON: "/v2/user-manager/group/:groupId/person/:personId",
            GROUP_ADD_PEOPLE: "/v2/user-manager/group/:id",
            GROUP_EDIT_PEOPLE: "/v2/user-manager/view/group",
            USER_WITHOUT_BADGE: "/v2/user-manager/user-without-badge-list",

            // PERSON
            GET_PEOPLE: "/v2/user-manager/person/all",
            GET_FILTERED_PEOPLE: "/v2/user-manager/peopleFilter",
            GET_PEOPLE_WAITING_ACCOUNT: "/v2/user-manager/person/waiting-account",
            GET_PEOPLE_NO_ACCESS_CONTROL: "/v2/user-manager/person/no-access-control",
            GET_PEOPLE_BY_COMPANY: "/v2/user-manager/person/:companyId",
            PERSON: "/v2/user-manager/person",
            DELETE_PERSON: "/v2/user-manager/person/:id",
            GET_PERSON_HISTORY: "/v2/user-manager/person/:id/history",
            GET_PERSON_FOR_EDIT: "/v2/user-manager/view/person",
            GET_PERSON_ACCOUNT_FOR_EDIT: "/v2/user-manager/view/account",
            GET_AREA_LIST: "/v2/user-manager/company/:companyId/list-all-area",

            // People Caring Module
            LIST_LILT_RESERVATION: "/v2/people-caring/reservation/sites",
            ADD_EDIT_LILT_RESERVATION: "/v2/people-caring/reservation/site",
            DELETE_LILT_RESERVATION: "/v2/people-caring/reservation/site/delete",
            LIST_LILT_ITEM: "/v2/people-caring/reservation/site/item/list",
            ADD_EDIT_ITEM_LILT_RESERVATION: "/v2/people-caring/reservation/site/item/add-edit",
            DELETE_LILT_ITEM: "/v2/people-caring/reservation/site/item/delete",
            PEOPLE_CARING_SCOPES_LIST: "/v2/people-caring/scope/list",
            ADD_EDIT_PEOPLE_CARING_SCOPES: "/v2/people-caring/scope/add-edit",
            VIEW_PEOPLE_CARING_SCOPES: "/v2/people-caring/scope/view",
            DELETE_PEOPLE_CARING_SCOPES: "/v2/people-caring/scope/delete",
            PEOPLE_CARING_SETTINGS: "/v2/people-caring/setting/update",
            PEOPLE_CARING_SETTINGS_PAGE: "/v2/people-caring/setting/update",

            SITE_RESERVATION: "/v2/people-caring/user/reservation/item/:itemId/:userId",
            DOWNLOAD_CSV_OF_SITE:"/v2/people-caring/csv/user/reservation/site/:id",
            DOWNLOAD_CSV_PEOPLE_CARING: "/v2/people-caring/csv/user/pre-reservation/site",
            PEOPLE_CARING_INITIATIVE_LIST: "/v2/people-caring/initiative/list",
            PEOPLE_CARING_INITIATIVE_ADD_EDIT: "/v2/people-caring/initiative/add-edit",
            PEOPLE_CARING_INITIATIVE_VIEW: "/v2/people-caring/initiative/view",
            PEOPLE_CARING_INITIATIVE_DELETE: "/v2/people-caring/initiative/delete",
            PEOPLE_CARING_SETTINGS_PAGE_EMAIL_CONFIG:"/v2/people-caring/setting",
            PEOPLE_CARING_SETTINGS_Email_VIEW:"/v2/people-caring/email-configuration/view",
            PEOPLE_CARING_SETTINGS_Email_EDIT:"/v2/people-caring/email-configuration/edit",
            PEOPLE_CARING_DOWNLOAD_BOOKINGS: '/v2/people-caring/reservation/download/xlsx',



            // Flu Vaccine Module
            LIST_FLU_VACCINE: "/v2/flu-vaccine/reservation/sites",
            ADD_EDIT_FLU_VACCINE: "/v2/flu-vaccine/reservation/site",
            DELETE_FLU_VACCINE: "/v2/flu-vaccine/reservation/site/delete",
            LIST_FLU_VACCINE_ITEM: "/v2/flu-vaccine/reservation/site/item/list",
            ADD_EDIT_ITEM_FLU_VACCINE: "/v2/flu-vaccine/reservation/site/item/add-edit",
            DELETE_FLU_VACCINE_ITEM: "/v2/flu-vaccine/reservation/site/item/delete",

            // ACCOUNT
            GET_ADMIN: "/v2/user-manager/admin",
            GET_ACCOUNTS_BY_COMPANY: "/v2/user-manager/accounts/:companyId",
            DELETE_ACCOUNT: "/v2/user-manager/account/:id",
            ADD_USER_ACCOUNT: "/v2/user-manager/account/add",
            UPDATE_USER_ACCOUNT: "/v2/user-manager/account/update",
            // UPLOAD_USERS: "/v2/user-manager/users/upload",
            UPLOAD_USERS: "/v2/user-manager/import/accounts",
            // UPLOAD_USERS_LIST: "/v2/user-manager/users/listupload",
            UPLOAD_USERS_LIST: "/v2/user-manager/users/people/import",
            DOWNLOAD_USERS_LIST: "/v2/user-manager/users/listdownload/:companyId/:scope",
            LOGOUT: '/v2/user-manager/logout',


            UPLOAD_REQUESTS_LIST: "/v2/approval/requests/import",

            // GREENPASS
            GET_GREENPASSES: "/v2/user-manager/greenpass/all",
            GET_GREENPASSES_BY_USER: "/v2/user-manager/greenpass/:userId",
            DELETE_GREENPASS: "/v2/user-manager/greenpass/:id",
            ADD_GREENPASS: "/v2/user-manager/greenpass/add",
            UPDATE_GREENPASS: "/v2/user-manager/greenpass/update",
            UPLOAD_GREENPASS: "/v2/user-manager/greenpass/upload-document/:userId/:companyId",
            VALIDATE_GREENPASS: "/v2/user-manager/greenpass/validate/:companyId",

            GET_TODAY_USER_GREENPASS_VALIDATION: "/v2/user-manager/greenpass/validation/today/:userId",
            ADD_GREENPASS_VALIDATION: "/v2/user-manager/greenpass/validation/today",
            DELETE_GREENPASS_VALIDATION: "/v2/user-manager/greenpass/validation/:id",

            // SITE
            GET_SITES: "/v2/site-manager/sites",
            GET_FULL_SITE_LIST: "/v2/site-manager/company-sites",
            ADD_UPDATE_SITE: "/v2/site-manager/site",
            ADD_SITE: "/v2/site-manager/site/add",
            UPDATE_SITE: "/v2/site-manager/site/update",
            VIEW_UPDATE_SITE: "/v2/site-manager/site/view",
            DELETE_SITE: "/v2/site-manager/site/:id",
            SITE_RESTRICTED_LIST: "/v2/site-manager/site-capacity-rages/:id",
            SITE_RESTRICTED_ADD_EDIT: "/v2/site-manager/site-capacity-rages/:id",
            GET_USER_AREA: "/v2/user-manager/areas",





// COMPANY
            GET_COMPANY: "/v2/user-manager/company/:id",
            UPDATE_COMPANY: "/v2/user-manager/company",
            GET_COMPANY_FUNCTIONS: "/v2/user-manager/company/functions",
            GET_COMPANY_FUNCTIONS_BY_GROUPS: "/v2/user-manager/company/functions-by-groups",
            GET_COMPANY_MOBILE_FUNCTIONS_BY_GROUPS: "/v2/user-manager/company/mobile-tiles-functions-by-groups",
            ADD_COMPANY_JUSTIFICATION: "/v2/user-manager/company/:id/justification",
            COMPANY_JUSTIFICATION: "/v2/user-manager/company/:id/justification/:justificationId",
            ADD_COMPANY_SCOPE: "/v2/user-manager/company/:id/scope",
            COMPANY_SCOPE: "/v2/user-manager/company/:id/scope/:scopeName",
            ADD_COMPANY_AREA: "/v2/user-manager/company/:id/area",
            EDIT_COMPANY_AREA: "/v2/user-manager/company/:id/edit/area/:areaName",
            ADD_AREA_SCOPE_LIST: "/v2/user-manager/company/scope/list",
            COMPANY_AREA: "/v2/user-manager/company/:id/area/:areaName",
            DELETE_AREA_TREE: "/v2/user-manager/company/:id/area/:areaName",
            ADD_COMPANY_ROLE: "/v2/user-manager/company/:id/role/:role",
            UPDATE_COMPANY_ROLE: "/v2/user-manager/company/:id/role-update/:roleName/:role",
            DELETE_COMPANY_ROLE: "/v2/user-manager/company/:id/role-delete/:roleName",
            ADD_COMPANY_JOBTITLE: "/v2/user-manager/company/:id/jobTitle/:jobTitle",
            UPDATE_COMPANY_JOBTITLE: "/v2/user-manager/company/:id/jobTitle-update/:jobTitleName/:jobTitle",
            DELETE_COMPANY_JOBTITLE: "/v2/user-manager/company/:id/jobTitle-delete/:jobTitleName",
            ADD_COMPANY_DIRECTION: "/v2/user-manager/company/:id/direction/:direction",
            UPDATE_COMPANY_DIRECTION: "/v2/user-manager/company/:id/direction-update/:directionName/:direction",
            DELETE_COMPANY_DIRECTION: "/v2/user-manager/company/:id/direction-delete/:directionName",
            VIEW_AREA_DETAILS: "/v2/user-manager/company/:id/area/:areaName/view",
            GET_DIVISION_LIST: '/v2/user-manager/company/areas/division/list',
            VIEW_DIVISION: '/v2/user-manager/company/areas/division/view',
            ADD_EDIT_DIVISION: '/v2/user-manager/company/areas/division/add-edit',
            DELETE_DIVISION: '/v2/user-manager/company/areas/division/delete',
            MULTIPLE_DELETE_DIVISION: '/v2/user-manager/company/areas/division/delete-multiple-division',
            GET_UNIT_LIST: '/v2/user-manager/company/areas/division/unit/list',
            VIEW_UNIT: '/v2/user-manager/company/areas/division/unit/view',
            ADD_EDIT_UNIT: '/v2/user-manager/company/areas/division/unit/add-edit',
            DELETE_UNIT: '/v2/user-manager/company/areas/division/unit/delete',
            MULTIPLE_DELETE_UNITS: '/v2/user-manager/company/areas/division/delete-multiple-unit',
            EXECUTIVE_COMMITTE_LIST: '/v2/user-manager/executive-committe/list',
            EXECUTIVE_COMMITTE_DELETE: '/v2/user-manager/executive-committe/delete',
            EXECUTIVE_COMMITTE_ADD_EDIT: '/v2/user-manager/executive-committe/add-edit',
            EXECUTIVE_COMMITTE_VIEW: '/v2/user-manager/executive-committe/view',
            GET_AREA_TREE_DATA:'user-manager/company/area/list',
            ADD_Tree_AREA: "/v2/user-manager/company/area/add-edit",
            UPDATE_JUSTIFICATION_EMAIL_CONFIG:"/v2/user-manager/justification/update-email-configuration",
            GET_JUSTIFICATION_EMAIL_CONFIG:"/v2/user-manager/justification/get-email-configuration/:companyId",

            // CALENDAR
            GET_CALENDAR: "/v2/calendar/user/:year/:month",
            UPDATE_CALENDAR: "/v2/calendar/event/:calendarId/:date",

            // NEWS
            ALERT_UPLOAD: "/v2/alert/upload",
            ALERT_EDIT: '/v2/alert/edit',
            ALERT_DOCLIST: "/v2/alert/:userId",
            ALERT_LISTALL: "/v2/alert-list",
            ALERT_DOC: "/v2/alert/:id",
            ALERT_ACTION: "/v2/alert/action/:id",
            ALERT_DOWNLOAD: "/v2/alert/download/:id",


            // Ticket Generate
            TICKET_UPLOAD: "/v2/ticket/upload",
            TICKET_DOCLIST: "/v2/ticket/:userId",
            TICKET_LISTALL: "/v2/tickets",
            TICKET_DOC: "/v2/ticket/:id",
            TICKET_ACTION: "/v2/ticket/action/:id",
            TICKET_DOWNLOAD: "/v2/ticket/download/:id",

            // Recruiting Dashboard
            RECRUITING_DASHBOARD_MANAGEMEN: '/v2/mqs-recruiting/dashboard',
            RECRUITING_ANALYTICS_DASHBOARD_MANAGEMEN: '/v2/mqs-recruiting/analytics',

            // Recruiting Management Opening
            RECRUITING_OPENING_LISTING: "/v2/mqs-recruiting/job-listing",
            RECRUITING_OPENING_CREATE: "/v2/mqs-recruiting/job-create",
            RECRUITING_DATA_STORAGE_UPLOAD: "/v2/data-storage/upload",
            RECRUITING_DATA_STORAGE_UPLOAD_DEFAULT: "/v2/data-storage/upload-default",
            RECRUITING_DATA_STORAGE_IMAGE: "/v2/data-storage/image",
            RECRUITING_DATA_STORAGE_DETAILS: "/v2/data-storage/details",
            RECRUITING_DATA_STORAGE_DEFAULT_IMAGE: "/v2/data-storage/default-image",
            RECRUITING_DATA_STORAGE_DOWNLOAD: "/v2/data-storage/download/:id",
            RECRUITING_DATA_RESUME_STORAGE_DOWNLOAD: "/v2/data-storage/download-user/:id",
            RECRUITING_OPENING_DELETE: "/v2/mqs-recruiting/job-delete",
            RECRUITING_OPENING_QUIZ_LISTING: "/v2/mqs-quiz/quiz-list",
            RECRUITING_OPENING_QUIZ_DELETE: "/v2/mqs-quiz/quiz-delete",
            RECRUITING_DASHBOARD_LISTING: "/v2/mqs-dashboard/list",
            RECRUITING_OPENING_QUIZ_JOB_LISTING: "/v2/mqs-quiz/survey/question/list",
            RECRUITING_OPENING_CUSTOMFIELD_JOB_LISTING: "/v2/mqs-quiz/quiz-job-list-custom-field",
            RECRUITING_OPENING_JOB_VIEW: "/v2/mqs-recruiting/job-view",
            RECRUITING_OPENING_QRCODE_CREATE: "/v2/mqs-recruiting/qrcode-create",
            RECRUITING_OPENING_QRCODE_PARAM_URL: "/legance/job-details/##ID##",

            RECRUITING_OPENING_HISTORY_LISTING: "/v2/mqs-recruiting/history-listing",

            RECRUITING_DASHBOARD_SCOPES_LIST: '/v2/user-manager/company/scope',
            RECRUITING_DASHBOARD_EVENT_TYPE_LIST: '/v2/user-manager/company/event/type/:id',
            RECRUITING_DASHBOARD_EVENT_TYPE_DELETE: '/v2/user-manager/company/:id/justification/:justificationId',
            RECRUITING_DASHBOARD_DISPUTE_TYPE_LIST: '/v2/user-manager/company/:id/disputeList',
            RECRUITING_DASHBOARD_ADD_DISPUTE_TYPE_LIST: "/v2/user-manager/company/:id/dispute/add",
            RECRUITING_DASHBOARD_DELETE_DISPUTE: "/v2/user-manager/company/:id/dispute-delete/:disputeName",
            RECRUITING_DASHBOARD_EDIT_DISPUTE: "/v2/user-manager/company/:id/dispute-update/:disputeName",

            //SAFETY_MODULE
            SAFETY_LIST: '/v2/irina-core/safety/list',
            ADD_SAFETY: '/v2/irina-core/safety/register',
            EDIT_SAFETY: '/v2/irina-core/safety',
            DELETE_SAFETY: '/v2/irina-core/safety/delete',
            SAFETY_DETAIL: '/v2/irina-core/safety/detail/:id',
            SAFETY_CONFIGURATION_ADD: '/v2/irina-core/safety/configuration/add',
            SAFETY_CONFIGURATION_GET: '/v2/irina-core/safety/configuration/get',
            SAFETY_GET_DASHBOARD: '/v2/irina-core/safety/dashboard',


            //SCHEDULER_MODULE
            EVENT_SCHEDULER_LIST: '/v2/scheduler/events/list',
            ADD_EVENT_SCHEDULE: '/v2/scheduler/event',
            EDIT_EVENT_SCHEDULE: '/v2/scheduler/event/:id',
            DELETE_EVENT_SCHEDULE: '/v2/scheduler/event/delete',
            EXECUTE_EVENT_SCHEDULE: '/v2/scheduler/event/execute',
            EVENT_SCHEDULE_DETAIL: '/v2/scheduler/event/:id',
            LAST_EVENT_REPORT: '/v2/scheduler/last-event-report',
            EXPAND_LOG_DETAILS: '/v2/scheduler/event/activity-log-list',
            EVENT_SCHEDULER_LOG_LIST: '/v2/scheduler/event/logs',

            //SAFETY_CERTIFICATION_MODULE
            SAFETY_CERTIFICATION_LIST: '/v2/irina-core/safety/certificationList',
            ADD_SAFETY_CERTIFICATION: '/v2/irina-core/safety/certification',
            SAFETY_CERTIFICATION_DETAILS: '/v2/irina-core/safety/certification/detail/:id',
            EDIT_SAFETY_CERTIFICATION: '/v2/irina-core/safety/certification',
            DELETE_SAFETY_CERTIFICATION: '/v2/irina-core/safety/certification/delete',

            //MASTER_COST_CENTER_TYPE
            MASTER_COST_CENTER_TYPE_ADD: '/v2/event-manager/cost-center-type/add',
            MASTER_COST_CENTER_TYPE_EDIT: '/v2/event-manager/cost-center-type/edit',
            MASTER_COST_CENTER_TYPE_LIST: '/v2/event-manager/cost-center-type/list',
            MASTER_COST_CENTER_TYPE_DELETE: '/v2/event-manager/cost-center-type/delete',
            MASTER_COST_CENTER_TYPE_MULTIPLE_DELETE: '/v2/event-manager/delete/cost-center-type',

            //DOCUMENT_TYPE
            MASTER_DOCUMENT_TYPE_ADD: '/v2/event-manager/document-type/add',
            MASTER_DOCUMENT_TYPE_EDIT: '/v2/event-manager/document-type/edit',
            MASTER_DOCUMENT_TYPE_LIST: '/v2/event-manager/document-type/list',
            MASTER_DOCUMENT_TYPE_DELETE: '/v2/event-manager/document-type/delete',
            MASTER_DOCUMENT_TYPE_MULTIPLE_DELETE: '/v2/event-manager/delete/document-type',

            //STAFF_DOCUMENT_LIST
            MASTER_STAFF_DOCUMENT_LIST: '/v2/user-manager/documents/list/',
            MASTER_STAFF_DOCUMENT_ADD: '/v2/user-manager/documents/document',
            MASTER_STAFF_DOCUMENT_EDIT: '/v2/user-manager/staff-document/edit',
            MASTER_STAFF_DOCUMENT_DELETE: '/v2/user-manager/staff-document/delete',
            MASTER_STAFF_DOCUMENT_MULTIPLE_DELETE: '/v2/user-manager/documents/delete',
            MASTER_STAFF_DOCUMENT_VIEW: '/v2/user-manager/documents/document/view',

            RECRUITING_DASHBOARD_UNIVERSITY_LIST: "/v2/mqs-dashboard/university/list",
            RECRUITING_DASHBOARD_UNIVERSITY_SAVE: "/v2/mqs-dashboard/university/save",
            RECRUITING_DASHBOARD_UNIVERSITY_DELETE: "/v2/mqs-dashboard/university/delete",
            RECRUITING_DASHBOARD_UNIVERSITY_EDIT: "/v2/mqs-dashboard/university/edit",

            RECRUITING_DASHBOARD_DEGREE_LIST: "/v2/mqs-dashboard/degree/list",
            RECRUITING_DASHBOARD_DEGREE_SAVE: "/v2/mqs-dashboard/degree/save",
            RECRUITING_DASHBOARD_DEGREE_EDIT: "/v2/mqs-dashboard/degree/edit",
            RECRUITING_DASHBOARD_DEGREE_DELETE: "/v2/mqs-dashboard/degree/delete",

            RECRUITING_DASHBOARD_HIGH_SCHOOL_DEGREE_LIST: "/v2/mqs-dashboard/high-school-degree/list",
            RECRUITING_DASHBOARD_HIGH_SCHOOL_DEGREE_SAVE: "/v2/mqs-dashboard/high-school-degree/add-edit",
            RECRUITING_DASHBOARD_HIGH_SCHOOL_DEGREE_DELETE: "/v2/mqs-dashboard/high-school-degree/delete",

            RECRUITING_DASHBOARD_HIGH_SCHOOL_LIST: "/v2/mqs-dashboard/high-school/list",
            RECRUITING_DASHBOARD_HIGH_SCHOOL_SAVE: "/v2/mqs-dashboard/high-school/add-edit",
            RECRUITING_DASHBOARD_HIGH_SCHOOL_DELETE: "/v2/mqs-dashboard/high-school/delete",

            RECRUITING_DASHBOARD_CUSTOM_FIELD_LIST: "/v2/mqs-recruiting/custom-filed/list",
            RECRUITING_DASHBOARD_CUSTOM_FIELD_SAVE: "/v2/mqs-recruiting/custom-filed/save",
            RECRUITING_DASHBOARD_CUSTOM_FIELD_DELETE: "/v2/mqs-recruiting/custom-filed/delete",

            RECRUITING_DASHBOARD_VIDEO_QUESTION_LIST: "/v2/mqs-recruiting/video-question/list",
            RECRUITING_DASHBOARD_VIDEO_QUESTION_SAVE: "/v2/mqs-recruiting/video-question/save",
            RECRUITING_DASHBOARD_VIDEO_QUESTION_DELETE: "/v2/mqs-recruiting/video-question/delete",


            // Recruiting Management Opening
            RECRUITING_CANDIDATE_LISTING: "/v2/mqs-recruiting/candidate-listing",
            RECRUITING_CANDIDATE_CREATE: "/v2/mqs-recruiting/candidate-create",
            RECRUITING_CANDIDATE_DELETE: "/v2/mqs-recruiting/candidate-delete",

            // Recruiting Management Application
            RECRUITING_APPLICATION_LISTING: "/v2/mqs-recruiting/application-listing",
            RECRUITING_APPLICATION_BUDGE_COUNT: "/v2/mqs-recruiting/application-budge-count",
            RECRUITING_APPLICATION_CREATE: "/v2/mqs-recruiting/application-create",
            RECRUITING_APPLICATION_DELETE: "/v2/mqs-recruiting/application-delete",
            RECRUITING_APPLICATION_VIEW: '/v2/job-application/get-job-application',
            RECRUITING_APPLICATION_VIEW_Details: '/v2/mqs-recruiting/application/view',
            RECRUITING_CANDIDATE_APPLICATION_LISTING: "/v2/mqs-recruiting/candidate-application-listing",
            RECRUITING_CANDIDATE_APPLICATION_VIEW: "/v2/mqs-recruiting/candidate/view",
            RECRUITING_STATUS_READ_JOB_APPLICATION: "/v2/mqs-recruiting/read-job-application",
            RECRUITING_STATUS_READ_CANDIDATE: "/v2/mqs-recruiting/read-candidate",
            RECRUITING_STATUS_APPLICATION_LISTING: "/v2/mqs-recruiting/status-application-listing",
            RECRUITING_SAVE_HISTORY: "/v2/job-application/save-application-history",
            RECRUITING_GET_HISTORY: "/v2/job-application/list-application-history",
            RECRUITING_GET_SURVEY_METRIC: "/v2/job-application/list-application-fit-index",
            RECRUITING_JOB_OPENING_VIEW: "/v2/mqs-recruiting/job-view",
            RECRUITING_CANDIDATE_UNIVERSITIES: "/v2/mqs-recruiting/candidate/university",
            RECRUITING_CANDIDATE_AREAS: "/v2/mqs-recruiting/candidate/areas",
            GET_QUESTION_BY_JOB_APPLICATION: "/v2/mqs-quiz/getQuestionByJobApplication",
            RECRUITING_CANDIDATE_SEND_REMAINDER: "/v2/mqs-recruiting/candidate/remainder",
            RECRUITING_LIST_EMAIL_TEMPLATE_PROCURMENT: "/v2/mqs-recruiting/email-template/list",
            RECRUITING_UPDATE_EMAIL_TEMPLATE_PROCUREMENT: "/v2/mqs-recruiting/email-template/update",
            RECRUITING_RENEW_CANDIDATE_ACCOUNT: "/v2/mqs-recruiting/renew-candidate",
            RECRUITING_JOB_APPLICATION_FILTER_SURVEYLIST:"/v2/mqs-recruiting/get-survey",
            RECRUITING_JOB_APPLICATION_DOWNLOAD_CVS:"/v2/mqs-recruiting/download-cv",
            RECRUITING_JOB_APPLICATION_DOWNLOAD_AND_SEND_CVS:"/v2/mqs-recruiting/send-mail-cv",
            RECRUITING_JOB_APPLICATION_QUESTIONS_BY_SURVEY:"/v2/mqs-recruiting/get-survey-question",
            RECRUITING_JOB_APPLICATION_ANSWER_BY_QUESTION:"/v2/mqs-recruiting/get-answer",
            // INFORMATION
            INFO_UPLOAD: "/v2/info/upload",
            INFO_LISTALL: "/v2/info",
            INFO_DOC: "/v2/info/:id",
            INFO_DOWNLOAD: "/v2/info/download/:filename",
            INFO_CATEGORY_LISTALL: "/v2/info-category",
            INFO_CATEGORY_DOC: "/v2/info-category/:id",
            CREATE_CATEGORY: "/v2/info-new-category",
            UPDATE_CATEGORY: "/v2/info-category",
            CREATE_OR_UPDATE_CATEGORY: "/v2/info-category-create-or-update",
            GET_CATEGORY_BY_ID: "/v2/info-category/:id",

            // USER ACTION
            USER_ACTION_OFFICE: "/v2/user-manager/action/office/:siteKey",
            TOUCHPOINT_ACTION_OFFICE: "/v2/user-manager/action/office/:siteKey/:userId",
            USER_ACTION_DESK: "/v2/user-manager/action/desk/:deskId",
            USER_ACTION_SMARTWORKING: "/v2/user-manager/action/smartworking",
            USER_ACTION_LUNCH: "/v2/user-manager/action/lunch",
            USER_ACTION_OVERTIME: "/v2/user-manager/action/overtime",
            GET_USER_ACTIVITIES: "/v2/user-manager/person/:id/activities/:dateFrom/:dateTo",
            USER_ACTION_LUNCH_SETTINGS: "/v2/user-manager/action/lunch/settings",
            USER_ACTION_UPDATE: "/v2/user-manager/person/activity",
            PEOPLE_SW_DASHBOARD: "/v2/user-manager/swDashboard",
            GET_PEOPLE_LISTING: "/v2/user-manager/cr-planning-user-list",

            // APPROVAL REQUESTS
            UPDATE_REQUEST: "/v2/approval/request",
            GET_ALL_REQUESTS: "/v2/approval/requests/all",
            GET_USER_REQUESTS: "/v2/approval/requests/all/user/:userId",
            GET_ACCOUNTABLE_REQUESTS: "/v2/approval/requests/all/accountable/:accountableId",
            GET_RESPONSABLE_REQUESTS: "/v2/approval/requests/all/responsable/:responsableId",
            GET_CONSULTED_REQUESTS: "/v2/approval/requests/all/consulted/:consultedId",
            GET_INFORMED_REQUESTS: "/v2/approval/requests/all/informed/:informedId",

            GET_ALL_REQUESTS_BYSTATE: "/v2/approval/requests/all/state/:state",
            GET_USER_REQUESTS_BYSTATE: "/v2/approval/requests/user/state/:userId/:state",
            GET_ACCOUNTABLE_REQUESTS_BYSTATE: "/v2/approval/requests/accountable/state/:accountableId/:state",
            GET_RESPONSABLE_REQUESTS_BYSTATE: "/v2/approval/requests/responsable/state/:responsableId/:state",
            GET_CONSULTED_REQUESTS_BYSTATE: "/v2/approval/requests/consulted/state/:consultedId/:state",
            GET_INFORMED_REQUESTS_BYSTATE: "/v2/approval/requests/informed/state/:informedId/:state",

            GET_ALL_REQUESTS_BYDATE: "/v2/approval/requests/all/date/:start/:end",
            GET_USER_REQUESTS_BYDATE: "/v2/approval/requests/user/date/:userId/:start/:end",
            GET_ACCOUNTABLE_REQUESTS_BYDATE: "/v2/approval/requests/accountable/date/:accountableId/:start/:end",
            GET_RESPONSABLE_REQUESTS_BYDATE: "/v2/approval/requests/responsable/date/:responsableId/:start/:end",
            GET_CONSULTED_REQUESTS_BYDATE: "/v2/approval/requests/consulted/date/:consultedId/:start/:end",
            GET_INFORMED_REQUESTS_BYDATE: "/v2/approval/requests/informed/date/:informedId/:start/:end",

            GET_ALL_REQUESTS_BYJUSTIFICATIVE: "/v2/approval/requests/all/justificative/:justificative",
            GET_USER_REQUESTS_BYJUSTIFICATIVE: "/v2/approval/requests/user/justificative/:userId/:justificative",
            GET_ACCOUNTABLE_REQUESTS_BYJUSTIFICATIVE: "/v2/approval/requests/accountable/justificative/:accountableId/:justificative",
            GET_RESPONSABLE_REQUESTS_BYJUSTIFICATIVE: "/v2/approval/requests/responsable/justificative/:responsableId/:justificative",
            GET_CONSULTED_REQUESTS_BYJUSTIFICATIVE: "/v2/approval/requests/consulted/justificative/:consultedId/:justificative",
            GET_INFORMED_REQUESTS_BYJUSTIFICATIVE: "/v2/approval/requests/informed/justificative/:informedId/:justificative",

            GET_ALL_REQUESTS_BYUSER: "/v2/approval/requests/all/user/:userId",
            GET_ACCOUNTABLE_REQUESTS_BYUSER: "/v2/approval/requests/accountable/user/:accountableId/:userId",
            GET_RESPONSABLE_REQUESTS_BYUSER: "/v2/approval/requests/responsable/user/:responsableId/:userId",
            GET_CONSULTED_REQUESTS_BYUSER: "/v2/approval/requests/consulted/user/:consultedId/:userId",
            GET_INFORMED_REQUESTS_BYUSER: "/v2/approval/requests/informed/user/:informedId/:userId",

            GET_USER_ACTIVITIES_CSV: "/v2/user-manager/person/csv/:scope/:userId/activities/:dateFrom/:dateTo",
            GET_TIMECARD: "/v2/user-manager/time-card",

            // REPORT
            GET_REPORT_USERS_PRESENCE: "/v2/report/users/presence/:year/:month",


            // CMS
            CMS_DETAILS: "/v2/mqs-dashboard/cms",
            CMS_LIST: "/v2/mqs-dashboard/cms/list",
            CMS_SAVE_LIST: "/v2/mqs-dashboard/cms/save",
            CMS_PAGE_SAVE_LIST: "/v2/cms/save",
            CMS_PAGE_GET_LIST: "/v2/cms/get",
            RECRUITING_SAVE_DEFAULT_IMAGE: "/v2/mqs-dashboard/default-image/save",
            RECRUITING_GET_DEFAULT_IMAGE: "/v2/mqs-dashboard/default-image",
            CMS_DASHBOARD_UPDATE_SETTINGS: '/v2/mqs-dashboard/update-setting',
            GET_CMS_DASHBOARD_SETTINGS: '/v2/mqs-dashboard/get-setting',
            UPDATE_TUTORIAL_LIST: '/v2/cms/tutorial/change-order',
            GET_SCREENSAVER_LIST: '/v2/cms/touchpoint-screen-saver/list',
            VIEW_SCREENSAVER_DATA: '/v2/cms/touchpoint-screen-saver/get',
            ADD_EDIT_SCREENSAVER_DATA: '/v2/cms/touchpoint-screen-saver/add-edit',
            DELETE_SCREENSAVER: '/v2/cms/touchpoint-screen-saver/delete',

            // PRODUCT TRACKING
            SQL_SYNC: "/v2/product-tracking/sql/sync",

            ORDER_LIST: "/v2/product-tracking/orders",
            ORDER: "/v2/product-tracking/order",
            ORDER_BY_ID: "/v2/product-tracking/order/:id",
            ORDER_WITH_PRODUCT: "/v2/product-tracking/order/product/:productId/exists",
            UPDATE_ORDER_PRODUCT: "/v2/product-tracking/order/product/:productId",
            PRODUCT_ACTIVATE: "/v2/product-tracking/order/:orderId/product/:itemId/activate/:codeId",
            PRODUCT_UPDATE: "/v2/product-tracking/order/product/:codeId/status",
            ORDER_PRODUCTS: "/v2/product-tracking/order/:orderId/products",
            PRODUCT_BY_CODE: "/v2/product-tracking/order/products/:codeId",
            ALL_PRODUCTS: "/v2/product-tracking/products",
            DASHBOARD_INFO: "/v2/product-tracking/dashboard",
            PRODUCT_OPERATION_UPDATE: "/v2/product-tracking/order/product/:codeId/operation",

            LIST_ITEMS_COMPONENTS: '/v2/product-tracking/item-components',
            SYNC_ITEMS_COMPONENTS: '/v2/product-tracking/item-components/sync',

            LIST_ITEMS: '/v2/product-tracking/items',
            SYNC_ITEMS: '/v2/product-tracking/items/sync',
            ITEM_CODE: '/v2/product-tracking/item/:code',
            ITEM_CODE_EX: '/v2/product-tracking/item/get',
            ITEM_ENTRY: '/v2/product-tracking/item',
            ITEM_WORK_TIME_IMPORT: '/v2/product-tracking/items/work-time/import',

            LIST_CUSTOMER_ORDERS: '/v2/product-tracking/customer-orders',
            LIST_CUSTOMER_ORDERS_GROUPED: '/v2/product-tracking/customer-orders/grouped/:group',
            SYNC_CUSTOMER_ORDERS: '/v2/product-tracking/customer-orders/sync',

            LIST_WORKING_ORDERS: '/v2/product-tracking/working-orders',
            SYNC_WORKING_ORDERS: '/v2/product-tracking/working-orders/sync',

            LIST_PRODUCTION_PLANS: '/v2/product-tracking/production-plans',
            SYNC_PRODUCTION_PLANS: '/v2/product-tracking/production-plans/sync/:date',

            LIST_WORKING_PLANS: '/v2/product-tracking/working-plans',
            SYNC_WORKING_PLANS: '/v2/product-tracking/working-plans/sync/:date',
            WORKING_PLANS_FINAL_PRODUCTS: '/v2/product-tracking/working-plans/final-products',
            LIST_OPERATOR_AGENDA: '/v2/product-tracking/working-plans/operators/agenda',
            GET_OPERATOR_PLANS_INFO: '/v2/product-tracking/working-plans/operators/plans/info',
            GET_MISSING_ACTIVATION: '/v2/product-tracking/working-plans/missing/activations',

            LIST_WORKING_CENTERS: '/v2/product-tracking/working-centers',
            SYNC_WORKING_CENTERS: '/v2/product-tracking/working-centers/sync',

            LIST_WORKING_SHIFTS: '/v2/product-tracking/working-shifts',
            ENTRY_WORKING_SHIFT: '/v2/product-tracking/working-shift',
            WORKING_SHIFT_ID: '/v2/product-tracking/working-shift/:id',

            PROD_WORKFLOW_RESOURCE_PLANS: '/v2/product-tracking/workflow/resource/:id/plan/:date',
            PROD_WORKFLOW_RESOURCE_ITEM_ACTIONS: '/v2/product-tracking/workflow/resource/:id/item/:qr/action/:code',
            PROD_WORKFLOW_RESOURCE_ITEM_ACTIONS_EX: '/v2/product-tracking/workflow/resource/:id/item/:qr/get-action',
            PROD_WORKFLOW_RESOURCE_ACTIVATE_PLAN_ITEM: '/v2/product-tracking/workflow/resource/:id/activate/item/:qr',
            PROD_WORKFLOW_RESOURCE_MANUAL_ACTIVATE_PLAN_ITEM: '/v2/product-tracking/workflow/resource/:id/manual/activate/item/:qr',
            PROD_WORKFLOW_ASSIGN: '/v2/product-tracking/workflow/assign',
            PROD_WORKFLOW_UPDATE: '/v2/product-tracking/workflow/update',
            PROD_WORKFLOW_ITEM_BY_CODE: '/v2/product-tracking/workflow/item/:qr',
            PROD_WORKFLOW_OPERATOR_CAPACITY: '/v2/product-tracking/workflow/operator/capacity/:date',
            PROD_WORKFLOW_OPERATOR_ITEMS: '/v2/product-tracking/workflow/operator/production/items',
            PROD_WORKFLOW_OPERATOR_CHECK_ACTIVATE_ITEM: '/v2/product-tracking/workflow/operator/production/check/activate',
            PROD_WORKFLOW_OPERATOR_ACTIVATE_ITEM: '/v2/product-tracking/workflow/operator/production/activate',
            PROD_WORKFLOW_OPERATOR_DEACTIVATE_ITEM: '/v2/product-tracking/workflow/operator/production/deactivate',
            PROD_WORKFLOW_OPERATOR_ITEM_ACTION: '/v2/product-tracking/workflow/operator/production/item/actions',
            PROD_WORKFLOW_OPERATOR_ITEM_STATE: '/v2/product-tracking/workflow/operator/production/item/state',
            PROD_WORKFLOW_OPERATOR_ITEM: '/v2/product-tracking/workflow/operator/production/:id',
            PROD_WORKFLOW_ITEMS_MOVE_TO_PRODUCTION: '/v2/product-tracking/workflow/items/move/production',
            PROD_WORKFLOW_VALIDATE_CONTAINER: '/v2/product-tracking/workflow/validate/container',
            PROD_WORKFLOW_ITEM_CONTAINER_ACTION: '/v2/product-tracking/workflow/item/container/action',
            PROD_WORKFLOW_VALIDATE_PACKAGE: '/v2/product-tracking/workflow/validate/package-instance',
            PROD_WORKFLOW_ITEM_PACKAGE_ACTON: '/v2/product-tracking/workflow/package-instance/action',

            PACKAGE_TYPES: '/v2/product-tracking/packing-types',
            PACKAGE_TYPE: '/v2/product-tracking/packing-type',
            PACKAGE_TYPE_ID: '/v2/product-tracking/packing-type/:id',
            PACKAGE_TYPE_IMPORT: '/v2/product-tracking/packing-types/import',
            SHIPPING_AREAS: '/v2/product-tracking/shipping-areas',
            SHIPPING_AREA: '/v2/product-tracking/shipping-area',
            SHIPPING_AREA_ID: '/v2/product-tracking/shipping-area/:id',
            EXTRA_ACTIVITIES: '/v2/product-tracking/extra-activities',
            EXTRA_ACTIVITY: '/v2/product-tracking/extra-activity',
            EXTRA_ACTIVITY_ID: '/v2/product-tracking/extra-activity/:id',
            PRODUCT_DEFECTS: '/v2/product-tracking/product-defects',
            PRODUCT_DEFECT: '/v2/product-tracking/product-defect',
            PRODUCT_DEFECT_ID: '/v2/product-tracking/product-defect/:id/:scope',
            PRODUCT_CONTAINERS: '/v2/product-tracking/product-containers',
            PRODUCT_CONTAINER: '/v2/product-tracking/product-container',
            PRODUCT_CONTAINER_ID: '/v2/product-tracking/product-container/:id',
            PACKAGE_INSTANCES: '/v2/product-tracking/package-instances',
            PACKAGE_INSTANCE: '/v2/product-tracking/package-instance',
            PACKAGE_INSTANCE_INSPECT: '/v2/product-tracking/package-instance/inspect',
            PACKAGE_INSTANCE_PRINT_QR: '/v2/product-tracking/package-instance/print-qr',

            ORDER_DESTINATIONS: '/v2/product-tracking/order-destinations',

            // PARKING
            PARKING_STATUS: "/v2/parking/status/:action",
            PARKING_BOOK: "/v2/parking/book",
            PARKING_BOOK_REMOVE: "/v2/parking/book/:id",
            PARKING_BOOK_FOR_USER: "/v2/parking/book/:date/:id",
            PARKING_RESOURCES: "/v2/parking/resources",
            PARKING_GET_PERSONAL: "/v2/parking/personal",
            PARKING_UPDATE_POOL: "/v2/parking/pool/:id",
            PARKING_GET_CSV: "/v2/parking/csv",
            PARKING_PREBOOK: "/v2/parking/prebook",
            PARKING_BOOK_AVAILABLE: "/v2/parking/book/available",
            PARKING_STATISTICS: "/v2/parking/statistics",

            // DATA REPOSITORY:
            DATA_REPOSITORY_REF: '/v2/data-repository/:repository/:id',
            DATA_REPOSITORY_LIST: '/v2/data-repository/list/:repository/entry',


            // DATA STORAGE
            DATA_STORAGE_UPLOAD: "/v2/data-storage/upload",
            DATA_STORAGE_DOWNLOAD: "/v2/data-storage/download/:id",
            DATA_STORAGE_REMOVE: "/v2/data-storage/remove/:id",
            DATA_STORAGE_FILE_DETAILS: "/v2/data-storage/details",
            DATA_STORAGE_GET_BASE64: "/v2/data-storage/image",

            BELL_NOTIFICATION_LIST: '/v2/mqs-recruiting/notification-listing',
            PUSH_NOTIFICATION: '/v2/mqs-recruiting/push-notification/add',
            DELETE_PUSH_NOTIFICATION: '/v2/mqs-recruiting/push-notification/delete',
            NOTIFICATION_READ_UNREAD: "/v2/mqs-recruiting/notification/read-unread",
            ADDRESS_COUNTRY_LISTING: 'v2/mqs-procurement/country/list',
            ADDRESS_ADD_COUNTRY: "v2/mqs-procurement/country/add",

            // ACCESS CONTROL LOGS
            READ_ACLOGS: "/v2/access-control-manager/logs/read",
            LIST_ACLOGS_BY_SITE: "/v2/access-control-manager/logs/by-site/:id",
            LIST_ACLOGS_BY_UNIT: "/v2/access-control-manager/logs/by-unit",
            LIST_ACLOGS_BY_DATE: "/v2/access-control-manager/logs/by-date/:start/:end",
            LIST_ACLOGS_BY_SITE_AND_DATE: "/v2/access-control-manager/logs/by-site-date-range/:id/:start/:end",
            LIST_ACLOGS_BY_UNIT_AND_DATE: "/v2/access-control-manager/logs/by-unit-date-range/:start/:end",

            // ACCESS CONTROL USER QR CODE
            GENERATE_ACCESS_CONTROL_QR_CODE: "/v2/access-control-manager/generate-qr-code/:id",
            GET_ACCESS_CONTROL_QR_CODE: "/v2/access-control-manager/qr-code/:id",
            GET_ACCESS_CONTROL_BADGE: "/v2/access-control-manager/badge",
            BLOCK_USER_AC_QR_CODE: "/v2/access-control-manager/block-user-qrcode/:id",
            LIST_USER_AC_QR_CODE: "/v2/access-control-manager/list-user-qr-code",
            GET_USER_AC_QR_CODE: "/v2/access-control-manager/get-user-qr-code/:userId",
            ACTIVATE_USER_RING: "/v2/access-control-manager/activate-ring/:gateId",

            // CUSTOM QR CODE OLD
            GENERATE_CUSTOM_ACCESS_CONTROL_QR_CODE: "/v2/access-control-manager/generate-custom-qr-code",
            UPDATE_CUSTOM_ACCESS_CONTROL_QR_CODE: "/v2/access-control-manager/update-custom-qr-code",
            GET_CUSTOM_AC_QR_CODE: "/v2/access-control-manager/custom-qr-code/:id",
            LIST_CUSTOM_AC_QR_CODE: "/v2/access-control-manager/list-custom-qr-code",
            BLOCK_CUSTOM_AC_QR_CODE: "/v2/access-control-manager/block-custom-qrcode/:id",
            DELETE_CUSTOM_AC_QR_CODE: "/v2/access-control-manager/delete-custom-qrcode/:id",

            //DOCUMENTS
            ADD_UPDATE_DOCUMENT: "/v2/user-manager/documents/document",
            GET_COMPANY_DOCUMENTS: "/v2/user-manager/documents/list/:companyId",
            ADD_UPDATE_STAFF_DOCUMENT: "/v2/user-manager/user-documents/user-document",
            GET_DOCUMENTS_SETTINGS: '/v2/user-manager/documents/get-settings',
            SAVE_DOCUMENTS_SETTINGS: '/v2/user-manager/documents/save-settings',
            SAVE_STAFF_DOCUMENTS_SETTINGS: '/v2/user-manager/staff-document-send-notice-settings',
            SAVE_DOCUMENTS_EMAIL_CONFIGURATION: '/v2/user-manager/documents/save-email-config',
            GET_DOCUMENTS_EMAIL_CONFIGURATION: '/v2/user-manager/documents/get-email-config',
            GET_DOCUMENTS_EMAIL_TEMPLATE: '/v2/user-manager/documents/list-email-template',
            GET_VISITOR_EMAIL_TEMPLATE: '/v2/visitor/settings/email-template/list',
            UPDATE_DOCUMENTS_EMAIL_TEMPLATE: '/v2/user-manager/documents/update-email-template',
            UPDATE_VISITOR_EMAIL_TEMPLATE: '/v2/visitor/settings/email-template/update',

            //USER DOCUMENTS
            ADD_UPDATE_USER_DOCUMENT: "/v2/user-manager/user-documents/user-document",
            GET_USER_DOCUMENTS: "/v2/user-manager/user-documents/list",
            LIST_DELETED_USER_DOCUMENTS: "/v2/user-manager/user-documents/list-deleted",
            GET_DOCUMENT_PATH: "/v2/user-manager/user-documents/path/:documentId",

            //Event Management scope
            EVENT_MANAGEMENT_SCOPES_LIST: "/v2/event-manager/scope/list",
            EVENT_MANAGEMENT_SCOPES_DELETE: "/v2/event-manager/scope/delete",
            EVENT_MANAGEMENT_SCOPES_ADD: "/v2/event-manager/scope/add",
            EVENT_MANAGEMENT_SCOPES_EDIT: "/v2/event-manager/scope/edit",
            EVENT_MANAGEMENT_SCOPES_MULTIPLE_DELETE: "/v2/event-manager/delete/scope",
            EVENT_MANAGEMENT_EVENT_SCOPES_LIST: "/v2/event-manager/event/scope/list",

            //EventManagement external venues
            EVENT_MANAGEMENT_EXTERNAL_VENUES_LIST: "/v2/event-manager/external-venue/list",
            EVENT_MANAGEMENT_EXTERNAL_VENUES_DELETE: "/v2/event-manager/external-venue/delete",
            EVENT_MANAGEMENT_EXTERNAL_VENUES_ADD: "/v2/event-manager/external-venue/add",
            EVENT_MANAGEMENT_EXTERNAL_VENUES_EDIT: "/v2/event-manager/external-venue/edit",
            EVENT_MANAGEMENT_EXTERNAL_VENUES_MULTIPLE_DELETE: "/v2/event-manager/delete/external-venue",

            //Event Management scope
            EVENT_MANAGEMENT_COST_CENTER_LIST: "/v2/event-manager/cost-center/list",
            EVENT_MANAGEMENT_COST_CENTER_DELETE: "/v2/event-manager/cost-center/delete",
            EVENT_MANAGEMENT_COST_CENTER_ADD: "/v2/event-manager/cost-center/add",
            EVENT_MANAGEMENT_COST_CENTER_EDIT: "/v2/event-manager/cost-center/edit",
            EVENT_MANAGEMENT_COST_CENTER_MULTIPLE_DELETE: "/v2/event-manager/delete/cost-center",
            EVENT_MANAGEMENT_COST_CENTER_TYPE_LIST: "/v2/event-manager/cost-center-type-list",
            EVENT_MANAGEMENT_EVENT_COST_CENTER_LIST: "/v2/event-manager/event/costCenter/list",

            //Event Management event services
            EVENT_MANAGEMENT_EVENT_SERVICES_LIST: "/v2/event-manager/event-service/list",
            EVENT_MANAGEMENT_EVENT_SERVICES_DELETE: "/v2/event-manager/event-service/delete",
            EVENT_MANAGEMENT_EVENT_SERVICES_ADD: "/v2/event-manager/event-service/add",
            EVENT_MANAGEMENT_EVENT_SERVICES_EDIT: "/v2/event-manager/event-service/edit",
            EVENT_MANAGEMENT_EVENT_SERVICES_MULTIPLE_DELETE: "/v2/event-manager/delete/event-service",

            //Event Managment List Of Event
            EVENT_MANAGEMENT_LIST_OF_EVENT_LIST: "/v2/event-manager/event/list",
            EVENT_MANAGEMENT_CREATE_EVENT: "/v2/event-manager/event/add",
            EVENT_MANAGEMENT_EDIT_EVENT: "/v2/event-manager/event/edit",
            EVENT_CHECK_IN_DATA: "/v2/event-manager/event/get-event-checkin-data",
            EVENT_MANAGEMENT_EDIT_ATTENDEES_CREDITS: "/v2/event-manager/event/edit-event-credit",
            EVENT_MANAGEMENT_LIST_OF_EVENT_MULTIPLE_DELETE: "/v2/event-manager/delete/event",
            EVENT_MANAGEMENT_LIST_OF_EVENT_DELETE: "/v2/event-manager/event/delete",
            EVENT_MANAGEMENT_LIST_OF_EVENT_CANCEL: "/v2/event-manager/event/cancel",
            EVENT_MANAGEMENT_LIST_OF_EVENT_RE_ACTIVATE: "/v2/event-manager/event/re-active",
            EVENT_MANAGEMENT_LIST_OF_EVENT_ACTIVITY_LOG: "/v2/event-manager/event/activityLogList",
            EVENT_MANAGEMENT_LIST_OF_EVENT_Attendee_ACTIVITY_LOG: "/v2/event-manager/event/AttendeesActivityLogList",
            EVENT_MANAGEMENT_EDIT_EVENT_DETAILS_COUNT: "/v2/event-manager/event/insights",
            EVENT_MANAGEMENT_SEND_PUSH_NOTIFICATION: '/v2/event-manager/event/send/push-notification',
            EVENT_MANAGEMENT_WEBEX_CHECKIN_OUT: '/v2/event-manager/event/webex-checkIn-out',
            EVENT_MANAGEMENT_SEND_PERSONAL_PINCODE_EMAIL: '/v2/event-manager/event/send-personal-pincode-email',
            EVENT_MANAGEMENT_SEND_INDIVIDUAL_PERSONAL_PINCODE_EMAIL : "/v2/event-manager/event/send-personal-pincode-email-individual",
            EVENT_MANAGEMENT_ACTIVE_MEETING_LINK: "/v2/event-manager/event/active-meeting-link",
            EVENT_MANAGEMENT_CHECKPOINT_CONTROL: "/v2/event-manager/event/checkpoint/control",
            DOWNLOAD_CHECK_USER:"/v2/event-manager/event/download-check-users/:eventId",

    //Create Event
            EVENT_MANAGEMENT_LIST_OF_ASSET_TYPE: "/v2/event-manager/event/resource-types",
            EVENT_MANAGEMENT_LIST_OF_RESOURCE_BY_ASSET_TYPE: "/v2/event-manager/event/resource-by-types",
            GET_PEOPLE_BY_EVENT_COMPANY: "/v2/user-manager/event/person/:companyId",
            EVENT_MANAGEMENT_RESEND_INVITATION_EMAIL: '/v2/event-manager/event/resend-invitation-email',
            EVENT_MANAGEMENT_RESEND_INVITATION_USER: '/v2/event-manager/event/resend-invitation-user',
            EVENT_MANAGEMENT_SEND_ATTENDEE_CERTIFICATE: '/v2/event-manager/event/send-attendee-certificate',
            EVENT_MANAGEMENT_SEND_REMINDER_INVITATION: '/v2/event-manager/event/send-reminder-invitation',
            RESOURCE_TYPES_EVENT_MANAGEMENT: '/v2/booking/resource/types',
            RESOURCE_GROUP_BASED_ON_TYPES_EVENT_MANAGEMENT: '/v2/booking/resources/event/:resourceGroupId',



            //Calendar
            EVENT_MANAGEMENT_GET_CALENDER_EVENTS: "/v2/event-manager/event/calender",

            //Reservation
            EVENT_MANAGEMENT_GET_CALENDER_RESERVATION_EVENTS : "/v2/booking/calendar/single-resource/reservations/:resourceId/:bookingMethod/:startDate/:endDate",
            RESERVEATION_GLOBAL_CALENDAR : "/v2/booking/calendar/resource-group/reservations/:resourceGroupId/:startDate/:endDate",
            //Setting
            EVENT_MANAGEMENT_LIST_EMAIL_CONFIG: "/v2/event-manager/list-email-configuration",
            EVENT_MANAGEMENT_LIST_GENERAL_SETTINGS: "/v2/event-manager/event/get-general-setting",
            EVENT_MANAGEMENT_UPDATE_EMAIL_CONFIG: "/v2/event-manager/add-email-configuration",
            EVENT_MANAGEMENT_UPDATE_GENERAL_SETTINGS: "/v2/event-manager/event/add-general-setting",
            EVENT_MANAGEMENT_LIST_EMAIL_TEMPLATE: "/v2/event-manager/list-email-template",
            EVENT_MANAGEMENT_LIST_EMAIL_TEMPLATE_PROCURMENT: "/v2/mqs-procurement/list-email-template",

            // Event Dashboard
            EVENT_MANAGEMENT_GET_DASHBOARD: "/v2/event-manager/event/dashboard",
            EVENT_MANAGEMENT_UPDATE_EMAIL_TEMPLATE: "/v2/event-manager/update-email-template",
            EVENT_MANAGEMENT_UPDATE_EMAIL_TEMPLATE_PROCUREMENT: "/v2/mqs-procurement/update-email-template",

            // Download ICS file
            EVENT_MANAGEMENT_CREATE_ICS_FILE: "/v2/event-manager/event/createICS",
            EVENT_MANAGEMENT_DOWNLOAD_ICS_FILE: "/v2/event-manager/event/downloadICS",

            // Event management
            EVENT_MANAGEMENT_ATTENDEES_CERTIFICATE_LIST: "/v2/event-manager/event/getAttendees",
            EVENT_MANAGEMENT_ATTENDEE_CHANGE_ROLE: '/v2/event-manager/event/change-attendee-role',
            EVENT_MANAGEMENT_ACCEPT_EVENT: "/v2/event-manager/event/accept-invitation",
            EVENT_MANAGEMENT_DECLINE_EVENT: "/v2/event-manager/event/decline-invitation",
            EVENT_CHECK_IN_OUT_ACTION: "/v2/event-manager/event/checkIn-out",

            //Assets Management
            ASSETS_MANAGEMENT_RESOURCE_GROUP_DELETE: "/v2/booking/resource/type/:id",
            ASSETS_MANAGEMENT_FACILITIES_LIST: '/v2/booking/facility/list',
            ASSETS_MANAGEMENT_ADD_EDIT_FACILITIES: '/v2/booking/facility/add-edit',
            ASSETS_MANAGEMENT_VIEW_FACILITIES: '/v2/booking/facility/view',
            ASSETS_MANAGEMENT_DELETE_FACILITIES: '/v2/booking/facility/delete',

            ASSETS_MANAGEMENT_LAYOUTS_LIST: '/v2/booking/layout/list',
            ASSETS_MANAGEMENT_ADD_EDIT_LAYOUT: '/v2/booking/layout/add-edit',
            ASSETS_MANAGEMENT_VIEW_LAYOUT: '/v2/booking/layout/view',
            ASSETS_MANAGEMENT_DELETE_LAYOUT: '/v2/booking/layout/delete',
            ASSETS_MANAGEMENT_BOOK_RESERVE_TIMEFRAME: '/v2/booking/reserve/timeAndDay/resource/:resourceId/book/da',
            ASSETS_MANAGEMENT_BOOK_RESERVE_TIMEFRAME_DA: '/v2/booking/reserve/timeAndDay/resource/:resourceId/timeframe/book/da',
            ASSETS_MANAGEMENT_EMAIL_CONFIGURATION: '/v2/booking/email-configuration',

            //EMC Mini app
            EMC_LOGIN: "/v2/user-manager/account/login/event-attendee",

            //MY TRAINING
            MY_TRAINING_LIST_OF_EVENT_LIST: '/v2/event-manager/event/list/training',
            MY_TRAINING_DOWNLOAD_ATTENDANCE_CERTIFICATE: '/v2/event-manager/event/download-attendee-certificate',
            MY_TRAINING_VIEW_ATTENDANCE_CERTIFICATE: '/v2/event-manager/event/view-attendee-certificate',
            //Public Website
            PUBLIC_WEBSITE_CREATE_EDIT_PAGE: "/v2/public-website/pages/create-edit-page",
            PUBLIC_WEBSITE_CREATE_EDIT_SECTION: "/v2/public-website/pages/create-edit-section",
            PUBLIC_WEBSITE_LIST_PAGE: "/v2/public-website/pages/list-page",
            PUBLIC_WEBSITE_VIEW_PAGE: "/v2/public-website/pages/view-page",
            PUBLIC_WEBSITE_DELETE_PAGE: "/v2/public-website/pages/delete-page",
            PUBLIC_WEBSITE_DELETE_SECTION: "/v2/public-website/pages/delete-section",
            PUBLIC_WEBSITE_OFFERS_AND_SERVICES_CREATE_EDIT: "/v2/public-website/offers-and-services/create-edit",
            PUBLIC_WEBSITE_OFFERS_AND_SERVICES_LIST: "/v2/public-website/offers-and-services/list",
            PUBLIC_WEBSITE_OFFERS_AND_SERVICES_VIEW: "/v2/public-website/offers-and-services/view",
            PUBLIC_WEBSITE_OFFERS_AND_SERVICES_DELETE: "/v2/public-website/offers-and-services/delete",
            PUBLIC_WEBSITE_EMAIL_CONFIGURATION_EDIT: "/v2/public-website/email-configuration/edit",
            PUBLIC_WEBSITE_EMAIL_CONFIGURATION_VIEW: "/v2/public-website/email-configuration/view",
            PUBLIC_WEBSITE_CONTACT_US_LIST: "/v2/public-website/contact-us/list",
            PUBLIC_WEBSITE_CONTACT_US_VIEW: "/v2/public-website/contact-us/view",
            PUBLIC_WEBSITE_CONTACT_US_ADD: "/v2/public-website/contact-us/add",
            PUBLIC_WEBSITE_CONTACT_US_DELETE: "/v2/public-website/contact-us/delete",
            PUBLIC_WEBSITE_CATEGORIES_ADD: "/v2/public-website/category/add",
            PUBLIC_WEBSITE_CATEGORIES_LIST: "/v2/public-website/category/list",
            PUBLIC_WEBSITE_CATEGORIES_VIEW: "/v2/public-website/category/view",
            PUBLIC_WEBSITE_CATEGORY_DELETE: "/v2/public-website/category/delete",
            PUBLIC_WEBSITE_CATEGORIES_DELETE: "/v2/public-website/categories/delete",
            PUBLIC_WEBSITE_SOCIAL_LIST: "/v2/public-website/social-setting/list",
            PUBLIC_WEBSITE_SOCIAL_EDIT: "/v2/public-website/social-setting/edit",
            PUBLIC_WEBSITE_TESTIMONIALS_CREATE_EDIT: "/v2/public-website/testimonial/add-edit",
            PUBLIC_WEBSITE_TESTIMONIALS_LIST: "/v2/public-website/testimonial/list",
            PUBLIC_WEBSITE_TESTIMONIALS_VIEW: "/v2/public-website/testimonial/view",
            PUBLIC_WEBSITE_TESTIMONIALS_DELETE: "/v2/public-website/testimonial/delete",
            PUBLIC_WEBSITE_OUR_TEAM_CREATE_EDIT: "/v2/public-website/our-team/add-edit",
            PUBLIC_WEBSITE_OUR_TEAM_LIST: "/v2/public-website/our-team/list",
            PUBLIC_WEBSITE_OUR_TEAM_VIEW: "/v2/public-website/our-team/view",
            PUBLIC_WEBSITE_OUR_TEAM_DELETE: "/v2/public-website/our-team/delete",
            PUBLIC_WEBSITE_PARTNERS_AND_AFFILIATES_CREATE_EDIT: "/v2/public-website/partener-and-affiliates/add-edit",
            PUBLIC_WEBSITE_PARTNERS_AND_AFFILIATES_LIST: "/v2/public-website/partener-and-affiliates/list",
            PUBLIC_WEBSITE_PARTNERS_AND_AFFILIATES_VIEW: "/v2/public-website/partener-and-affiliates/view",
            PUBLIC_WEBSITE_PARTNERS_AND_AFFILIATES_DELETE: "/v2/public-website/partener-and-affiliates/delete",
            PUBLIC_WEBSITE_ENQUIRY_LIST: "/v2/public-website/enquiry/list",
            PUBLIC_WEBSITE_ENQUIRY_VIEW: "/v2/public-website/enquiry/view",
            PUBLIC_WEBSITE_ENQUIRY_DELETE: "/v2/public-website/enquiry/delete",
            PUBLIC_WEBSITE_REGISTERED_USERS_LIST: "/v2/public-website/user/list",
            PUBLIC_WEBSITE_REGISTERED_USERS_DELETE: "/v2/public-website/user/delete",
            PUBLIC_WEBSITE_DASHBOARD: "/v2/public-website/dashboard",


            // FAQs
            FAQs_LISTALL: "/v2/faq/list",
            FAQs_ADD_EDIT: "/v2/faq/add",
            FAQs_DELETE: "/v2/faq/delete",


            // Tutorials
            Tutorial_LISTALL: "/v2/cms/tutorial/list",
            Tutorial_ADD_EDIT: "/v2/cms/tutorial/add-edit",
            Tutorial_DELETE: "/v2/cms/tutorial/delete",
            Tutorial_RESET_VIEWS: "/v2/user-manager/tutorial/views/reset",

            //Compliance Dashboard
            COMPLIANCE_LIST: '/v2/user-manager/company/compliance/list',
            // User Documents
            USER_DOCUMENTS_LIST:'/v2/user-manager/get-user-documents',


            // CHANGE API FOR SECURITY UPDATE

            // NEWS
            ALERT_ADD: '/v2/alert/add',
            ALERT_UPDATE: '/v2/alert/update',
            // NEWS/COMPANY_SITE LIST
            GET_COMPANY_SITE_NAME: '/v2/site-manager/get-company-sites-name',

            // TOUCH POINT
            EDIT_TOUCHPOINT: '/v2/touchpoint/edit-touchpoint',
            TOUCHPOINT_UNLOCK_PAIR_DA: '/v2/touchpoint/unlock/:id/da',
            // (TOUCHPOINT_SETTINGS Change with TOUCHPOINT_SETTINGS_RNP)
            TOUCHPOINT_SETTINGS_RNP: '/v2/touchpoint/settings/da',
            VIEW_EMAIL_CONFIGURATION_RNP: '/v2/touchpoint/email-configuration/view',

            // New changed API for resource type
            ADD_RESOURCE_TYPE:"/v2/booking/resource/add-resource-type",
            EDIT_RESOURCE_TYPE:"/v2/booking/resource/edit-resource-type",
            DROPDOWN_LIST_OF_RESOURCE_TYPE:"/v2/booking/resource/list-resource-type-dropdown",


            // New changed API for resource group
            ADD_RESOURCE_GROUP:"/v2/booking/resource-group/add",
            EDIT_RESOURCE_GROUP:"/v2/booking/resource-group/edit",
            SEARCH_RESOURCE_AVAILABILITY_DA:"/v2/booking/resource/search/availability/da",
            RESOURCE_BOOKING_TIMEFRAME_DA:"/v2/booking/reserve/:bookingMethod/resource/:resourceId/timeframe/book/da",
            GET_SITES_BASIC: "/v2/site-manager/sites-basic",
            RESOURCE_TYPE_GET_BY_ID_RNP: "/v2/booking/resource/view-resource-type-basic",
            ADD_BOOKABLE_RESOURCE_RNP: "/v2/booking/resource/add",
            EDIT_BOOKABLE_RESOURCE_RNP: "/v2/booking/resource/edit",

            // New changed API for asset management layouts
            ADD_LAYOUT :"/v2/booking/layout/add",
            EDIT_LAYOUT:"/v2/booking/layout/edit",

            // PEOPLE GROUP
            ADD_GROUP: '/v2/user-manager/group/add',
            UPDATE_GROUP: '/v2/user-manager/group/update',

            GET_PEOPLE_RNP: "/v2/user-manager/person/all-dropdown",


            // New changed API for asset management facilities
            ADD_FACILITIES_RNP :"/v2/booking/facility/add",
            EDIT_FACILITIES_RNP:"/v2/booking/facility/edit",

            // New changed API for asset management settings
            VIEW_ASSET_MANAGEMENT_SETTINGS_RNP:"/v2/booking/setting/view",
            EDIT_ASSET_SETTING_EMAIL_CONFIG_RNP:"/v2/booking/email-configuration/edit",
            VIEW_ASSET_SETTING_EMAIL_CONFIG_RNP:"/v2/booking/email-configuration/view",


            // New changed API for EVENT management
            EVENT_MANAGEMENT_SCOPES_LIST_RNP: "/v2/event-manager/scope/list-dropdown",
            EVENT_MANAGEMENT_LIST_GENERAL_SETTINGS_RNP: "/v2/event-manager/event/get-general-setting-basic",
            RESOURCE_GROUP_DROPDOWN_EVENT_MANAGEMENT_RNP:"/v2/booking/resource-group/list-dropdown",
            EVENT_MANAGEMENT_EVENT_SERVICES_LIST_DROPDOWN_RNP: "/v2/event-manager/event-service/list-dropdown",
            MASTER_DOCUMENT_TYPE_LIST_RNP: '/v2/event-manager/document-type/list-dropdown',
            EVENT_MANAGEMENT_COST_CENTER_LIST_RNP: "/v2/event-manager/cost-center/list-dropdown",
            EVENT_MANAGEMENT_EXTERNAL_VENUES_DROPDOWN_LIST: "/v2/event-manager/external-venue/list-dropdown",
            EVENT_MANAGEMENT_EVENT_SERVICES_UPDATE_RNP: "/v2/event-manager/event-service/update",
            MASTER_DOCUMENT_TYPE_UPDATE_RNP: '/v2/event-manager/document-type/update',
            EVENT_MANAGEMENT_SCOPES_UPDATE_RNP: "/v2/event-manager/scope/update",

            ADD_GROUP_RNP: '/v2/user-manager/add/group',
            UPDATE_GROUP_RNP: '/v2/user-manager/update/group',

            // New changed API for Information
            INFO_EDIT_RNP: '/v2/info/edit',
            CREATE_CATEGORY_RNP: '/v2/info-category-create',
            UPDATE_CATEGORY_RNP: '/v2/info-category-update',

            // Information
            INFO_CATEGORY_LIST_RNP: '/v2/info-category-dropdown',
            GET_PEOPLE_BY_COMPANY_RNP: "/v2/user-manager/person/:companyId/:scope",
            GET_PERSON_FOR_EDIT_RNP: "/v2/user-manager/view/person/:scope",
            PEOPLE_ADD_RNP: "/v2/user-manager/add/person/:scope",
            PEOPLE_UPDATE_RNP: "/v2/user-manager/update/person/:scope",
            ACTION_TILE_DESKTOP_FUNCTION_RNP: "/v2/user-manager/permission-function/update",


            // New changed API for Recruiting
            EDIT_JOB_OPENING_RNP:"/v2/mqs-recruiting/job-edit",
            GET_COMPANY_RNP:"/v2/user-manager/company/view",
            REGISTERED_USER_LISTING_RNP:"/v2/mqs-recruiting/registered-users-listing",
            QUIZ_LISTING_RNP:"/v2/mqs-quiz/survey/question/list-dropdown",
            // Questionnaires
            EDIT_MATRIX_RNP: '/v2/mqs-quiz/metric/edit',
            EDIT_QUESTION_RNP: '/v2/mqs-questions/edit',
            EDIT_SURVEY_RNP: '/v2/mqs-quiz/quiz-edit',
            ADD_SUSTAINABILITY_TRANS_TYPE_RNP: "/v2/sustainability/add/transport-type",
            UPDATE_SUSTAINABILITY_TRANS_TYPE_RNP: "/v2/sustainability/update/transport-type",
            ADD_SUSTAINABILITY_TRANS_NOTES_RNP: "/v2/sustainability/add/transport-note",
            UPDATE_SUSTAINABILITY_TRANS_NOTES_RNP: "/v2/sustainability/update/transport-note",
            SUSTAINABILITY_MOBILE_FIELDS_SAVE_RNP: "/v2/sustainability/update/mobile-app-fields-schema",
            UPLOAD_USERS_LIST_RNP: "/v2/user-manager/users/people/import/:scope",

            // New changed API for Visitor module
            EDIT_VISITORS_CATEGORY_RNP: '/v2/visitors/category/edit',
            VISITOR_USER_CATEGORYLIST_DROPDOWN_RNP:'/v2/visitors/category/list-dropdown',
            EDIT_EDIT_VISITOR_USER_RNP: "/v2/visitor/edit",
            RESOURCE_REMOVE_RESERVATION_RNP: '/v2/booking/parking/resource-group/booking/delete/all',
            GET_PERSON_FOR_VIEW_RNP: "/v2/user-manager/view/profile",
            DELETE_PERSON_RNP: "/v2/user-manager/person/:id/:scope",

            // Executive Committee
            EXECUTIVE_COMMITTEE_ADD_RNP: '/v2/user-manager/executive-committe/add',
            EXECUTIVE_COMMITTEE_EDIT_RNP: '/v2/user-manager/executive-committe/edit',

            // Master Module - Justification
            JUSTIFICATION_GET_COMPANY_RNP: '/v2/user-manager/company/:id/justification-details',
            JUSTIFICATION_EMAIL_CONFIGURATION_RNP: '/v2/user-manager/company/justification-configuration',

            // Master Module - Scope
            RECRUITING_DASHBOARD_EVENT_TYPE_LIST_RNP: '/v2/user-manager/company/justification/:id',

            // Master Module - University
            RECRUITING_DASHBOARD_UNIVERSITY_UPDATE_RNP: '/v2/mqs-dashboard/university/update',

            // Master Module - Degree
            RECRUITING_DASHBOARD_DEGREE_UPDATE_RNP: '/v2/mqs-dashboard/degree/update',

            // Master Module - Cost Center Type
            MASTER_COST_CENTER_TYPE_UPDATE_RNP: '/v2/event-manager/cost-center-type/update',

            // Master Module - Cost Center
            EVENT_MANAGEMENT_COST_CENTER_UPDATE_RNP: '/v2/event-manager/cost-center/update',

            // Master Module - High School
            RECRUITING_DASHBOARD_HIGH_SCHOOL_ADD_RNP: '/v2/mqs-dashboard/high-school/add',
            RECRUITING_DASHBOARD_HIGH_SCHOOL_EDIT_RNP: '/v2/mqs-dashboard/high-school/edit',

            // Master Module - High School Degree
            RECRUITING_DASHBOARD_HIGH_SCHOOL_DEGREE_ADD_RNP: '/v2/mqs-dashboard/high-school-degree/add',
            RECRUITING_DASHBOARD_HIGH_SCHOOL_DEGREE_EDIT_RNP: '/v2/mqs-dashboard/high-school-degree/edit',
            GET_COMPANY_BASIC: '/v2/user-manager/company/basic-info',
            GET_COMPANY_SETTINGS: '/v2/user-manager/company/settings',
            MASTER_STAFF_DOCUMENT_UPDATE_RNP: '/v2/user-manager/documents/document/update',
            MASTER_STAFF_DOCUMENT_DELETE_RNP: '/v2/user-manager/documents/delete',
            SAVE_STAFF_DOCUMENTS_SETTINGS_RNP: '/v2/user-manager/get-staff-document-send-notice-settings',
            LIST_ALL_STAFF_DOCUMENTS_RNP:'/v2/user-manager/documents/all-dropdown'
        }


    };

    config: Configurations;

    constructor(@Inject(Configurations) config: Configurations,
        private commonService: CommonService,
        private sessionManagementService: SessionManagementService,
        private _http: HttpClient) {
        //console.log('ApiService: contructor config: ', config);
        this.config = this.commonService.cloneObject(config);
    }

    prepare(api: string, apiParams: ApiParam[]) {
        for (let apiParam of apiParams)
            api = api.replace("{" + apiParam.paramname + "}", apiParam.paramvalue);
        return api;
    }

    resolveApiUrl(endpoint: string) {
        let apiUrl = this.config.env.api_host + endpoint;
        //console.log('ApiService.resolveApiUrl: ', apiUrl);
        return apiUrl;
    }

    /*getRetryCount() {
        try {
            return this.getRetryCount();
        } catch (ex) {
            return 5;
        }
    }*/

    getRetryCount() {
        return 0;
    }

    // tslint:disable-next-line:no-shadowed-variable
    get<T>(endpoint: string, options?: {}): Observable<T> {
        return this._http.get<T>(this.resolveApiUrl(endpoint), options).pipe(retry(this.getRetryCount()), tap(
            (data) => {
                return data;
            },
            (error: HttpErrorResponse) => {
                return this.handleError(error);
            }
        ));
    }

    post<T>(endpoint: string, body: any, options?: {}): Observable<T> {
        return this._http.post<T>(this.resolveApiUrl(endpoint), body, options).pipe(retry(this.getRetryCount()), tap(
            (data) => {
                return data;
            },
            (error: HttpErrorResponse) => {
                return this.handleError(error);
            }
        ));
    }

    put<T>(endpoint: string, body: any): Observable<T> {
        return this._http.put<T>(this.resolveApiUrl(endpoint), body).pipe(retry(this.getRetryCount()), tap(
            (data) => {
                return data;
            },
            (error: HttpErrorResponse) => {
                return this.handleError(error);
            }
        ));
    }

    delete<T>(endpoint: string): Observable<T> {
        return this._http.delete<T>(this.resolveApiUrl(endpoint)).pipe(retry(this.getRetryCount()), tap(
            (data) => {
                return data;
            },
            (error: HttpErrorResponse) => {
                return this.handleError(error);
            }
        ));
    }

    deleteWithPayload<T>(endpoint: string, payload?: any): Observable<T> {
        const options: any = {};

        if (payload) {
            options.body = payload;
        }

        return this._http.delete<T>(this.resolveApiUrl(endpoint), options).pipe(
            retry(this.getRetryCount()),
            catchError((error: HttpErrorResponse) => this.handleError(error))
        );
    }

    request(req: HttpRequest<any>): any {
        this._http.request(req).pipe(retry(this.getRetryCount()), tap(
            (data) => {
                return data;
            },
            (error: HttpErrorResponse) => {
                return this.handleError(error);
            }
        ));
    }

    uploadFile(file: File, endpoint: string): any {
        let formData: FormData = new FormData();
        formData.append("file", file, file.name);

        let url = this.resolveApiUrl(endpoint); //isSafetyUsers ? this.apiService.API.ADMIN.UPLOAD_SAFETY_USERS : this.apiService.API.BE.UPLOAD_USERS;
        let req = new HttpRequest("POST", url, formData, {
            reportProgress: true
        });

        return this._http.request(req).pipe(retry(this.getRetryCount()), tap(
            (data) => {
                return data;
            },
            (error: HttpErrorResponse) => {
                return this.handleError(error);
            }
        ));
    }

    private handleError(error: HttpErrorResponse) {
        console.error("Api error: ", error);

        // session expired
        if (error.status == 401) {
            this.sessionManagementService.fireSessionExpired();
        } else {
            console.error("Something went wrong. Please try again later");
        }

        return of(null);
    }
}

export let buildRequest = (string, params) =>
    string.split("/").map(el => {
        return params[el] ? params[el] : el
    }).join("/");
