import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserManagementService } from '../services/user-management.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor (
        private userManagementService: UserManagementService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request?.url?.includes("/account/login") == true) {
                // request = request
        }else{
            let currentToken = this.userManagementService.getToken();
            if (currentToken) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentToken}`
                    }
                });
            }
        }
        return next.handle(request);
    }
}
