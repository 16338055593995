// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    isSecure:true,
    // baseURL: 'http://localhost:10000/api/v2/'
    // baseURL: 'http://192.168.29.100:9000/api/v2/'
    // baseURL: 'http://122.169.113.151:9000/api/v2/'
    // baseURL: 'http://192.168.1.30:8000/api/v2/',
    baseURL: 'https://irina.nunow.eu/api/v2/',
    // baseURL: 'https://irina.legance.it/api/v2/'
    // baseURL: 'https://irina.nunow.eu/api/v2/'
    // baseURL: 'http://192.168.1.23:8000/api/v2/'
    // baseURL: 'http://192.168.1.41:8000/api/v2/'
    // baseURL: 'http://192.168.1.158:9000/api/v2/'
    // baseURL: 'http://192.168.1.158:8000/api/v2/'
    // baseURL: 'http://110.227.212.251:9000/api/v2/'
};
export const encryption_key = {
    key:'23O2dfHMGRhqKTw79sRsmkebU2Bb94NHmxNlfNFOm/RrDdfCYgfGcl3GnwY7Lfx0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
