import {Injectable} from '@angular/core';
import * as CryptoTS from 'crypto-ts';
import {encryption_key} from "../../../../fe-insights-v2/src/environments/environment";
import {environment} from "../../../../fe-insights-v2/src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class EncryptionService {
    private secure: any = environment.isSecure;
    private Key: string = encryption_key.key;

    constructor() {
        let oldIsSecure = localStorage.getItem('isSecure')
        if (oldIsSecure != this.secure.toString()) {
            localStorage.clear()
            location.reload()
            localStorage.setItem('isSecure', this.secure)
        }
    }

    encryptionAES(data: any) {
        const ciphertext = CryptoTS.AES.encrypt(data, this.Key);
        return ciphertext.toString();
    }

    decryptionAES(data: any) {
        try {
            if (data) {
                const bytes = CryptoTS.AES.decrypt(data, this.Key);
                const plaintext = bytes.toString(CryptoTS.enc.Utf8);
                return plaintext;
            } else {
                console.log('No Data Found');
                return null;
            }
        } catch (error) {
            console.error('Decryption error:', error);
            localStorage.clear();
            location.reload()
        }
    }

    getDataFromLocalStorage(key: string) {
        return this.decryptionAES(localStorage.getItem(key));
    }

    setDataToLocalStorage(key: string, value: any) {
        localStorage.setItem(key, this.encryptionAES(value));
    }
}
