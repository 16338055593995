import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {EncryptionService} from "./encryption.service";

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private readonly STORAGE_KEY = 'visitor-module';
    private storageData: { currentLanguage: string };
    private currentLanguageSubject = new BehaviorSubject<string>('');

    constructor(private encryptionService:EncryptionService) {
        const storedData = this.encryptionService.getDataFromLocalStorage(this.STORAGE_KEY);
        this.storageData = storedData ? JSON.parse(storedData) : { currentLanguage: 'it' };
        this.currentLanguageSubject.next(this.storageData.currentLanguage);
    }

    getCurrentLanguage(): Observable<string> {
        return this.currentLanguageSubject.asObservable();
    }

    setCurrentLanguage(lang: string): void {
        this.storageData.currentLanguage = lang;
        this.encryptionService.setDataToLocalStorage(this.STORAGE_KEY, JSON.stringify(this.storageData));
        this.currentLanguageSubject.next(lang); // Notify subscribers about the language change.
    }
}
