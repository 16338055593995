import {Component, OnInit} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {TranslateService} from '@ngx-translate/core';
import {visitorHelper} from '../../service/visitorHelper.service';
import {MCPHelperService} from '../../../../../fe-insights-v2/src/app/service/MCPHelper.service';
import {SettingsManagementService} from '../../../../../fe-common-v2/src/lib/services/settings-management.service';
import swal from 'sweetalert2';
import {FeVisitorService} from '../../../../../fe-common-v2/src/lib/services/fe-visitor.service';
import {LanguageService} from "../../service/language.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    language: any;
    userLanguageSelect: string;
    englishFlag = 'assets/images/en-flag.png';
    italianFlag = 'assets/images/ita-flag.png';
    alreadySelectedLang: any = false;
    primaryColor: any;

    constructor(
        private dateAdapter: DateAdapter<any>,
        private translate: TranslateService,
        private helper: visitorHelper,
        private settingManager: SettingsManagementService,
        private visitorAPIService: FeVisitorService,
        private languageService: LanguageService
    ) {
        this.userLanguageSelect = visitorHelper.dynamicData.language ?? 'it';
        this.helper.languageTranslator(this.userLanguageSelect);
        // this.loadPageData();
        this.language = [
            {
                languages: 'English',
                languagesFlagImg: 'assets/images/en-flag.png',
                languageCode: 'en',
                checked: true,
            },
            {
                languages: 'Italiano',
                languagesFlagImg: 'assets/images/ita-flag.png',
                languageCode: 'it',
                checked: true,
            }
        ];

        this.helper.languageList.subscribe((lanlist: any) => {
            this.language = lanlist;
            for (let i = 0; i < this.language.length; i++) {
                if (this.language[i].checked && (this.language[i].languageCode == this.userLanguageSelect)) {
                    this.alreadySelectedLang = true;
                    return;
                } else {
                    this.alreadySelectedLang = false;
                }
            }
            for (let i = 0; i < this.language.length; i++) {
                if (this.language[i].checked) {
                    if (this.alreadySelectedLang !== true) {
                        this.userLanguageSelect = this.language[i].languageCode;
                        this.helper.languageTranslator(this.userLanguageSelect);
                        this.translate.use(this.userLanguageSelect);
                        this.helper.setVisitorStorage('currentLanguage', this.userLanguageSelect);
                        return;
                    }
                }
            }
        });
    }

    async ngOnInit(): Promise<void> {
        this.userLanguageSelect = visitorHelper.getLanguageName();
        if (!this.userLanguageSelect) {
            this.translate.use('it');
            this.settingManager.setSettingsValue('currentLanguage', 'it');
        } else {
            this.translate.use(this.userLanguageSelect);
            this.settingManager.setSettingsValue('currentLanguage', this.userLanguageSelect);
        }
        this.userLanguageSelect = this.settingManager.getSettingsValue('currentLanguage');
        await this.getVisitorDetails();
    }

    itLocale() {
        this.dateAdapter.setLocale('it');
        this.changeLanguage('it');
    }

    enLocale() {
        this.dateAdapter.setLocale('en');
        this.changeLanguage('en');
    }

    changeLanguage(lang: string): void {
        this.languageService.setCurrentLanguage(lang);
        // window.location.reload();
    }

    onLanguageChange(valueLanguage: any): void {
        this.helper.languageTranslator(valueLanguage.value);
        this.translate.use(valueLanguage.value);
        this.changeLanguage(valueLanguage.value);
        this.helper.setVisitorStorage('currentLanguage', valueLanguage.value);
    }

    changeTheme(primaryColor: string) {
        document.documentElement.style.setProperty('--primary-color', primaryColor);
    }

    async getVisitorDetails(): Promise<any> {
        try {
            const res = await this.visitorAPIService.getVisitorData();
            if (res.result) {
                this.visitorAPIService.toggleLoaderVisibility(false);
                this.primaryColor = res.data?.brandColor;
                this.settingManager.setSettingsValue('primaryColor', this.primaryColor);
                this.primaryColor = this.settingManager.getSettingsValue('primaryColor') || '#14aab9';
                this.changeTheme(this.primaryColor);
            } else {
                swal.fire(
                    '',
                    this.translate.instant(res.reason),
                    'info'
                );
                this.visitorAPIService.toggleLoaderVisibility(false);
            }
        } catch (error) {
            swal.fire(
                '',
                this.translate.instant(error.error.message),
                'info'
            );
            this.visitorAPIService.toggleLoaderVisibility(false);
        }
    }
}
