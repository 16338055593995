import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FeVisitorService} from '../../../../../fe-common-v2/src/lib/services/fe-visitor.service';
import {MCPHelperService} from '../../../../../fe-insights-v2/src/app/service/MCPHelper.service';
import swal from 'sweetalert2';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SettingsManagementService} from '../../../../../fe-common-v2/src/lib/services/settings-management.service';
import {LanguageService} from "../../service/language.service";

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
    visitorForm: FormGroup;
    meansOfTransport: any = '';
    distance: any = '';
    reasonsForVisit: any = [];
    transportNotes: any[] = [];
    transportTypes: any[] = [];
    category: any[] = [];
    selectedUserType: any;
    successMessage = false;
    totalKM: any = 0;
    totalkgCO2: any = 0;
    privacyPolicy: false;
    userId: any;
    siteKey: any;
    pathObj: object = {};
    pathData: any[] = [];
    platform: any = [
        {name: 'Web', value: 'Web'},
        {name: 'App', value: 'App'}
    ];
    welcomeMessageEN = '';
    welcomeMessageIT = '';
    impactTextEN = '';
    impactTextIT = '';
    visitorEnvironmentImpactEN = '';
    visitorEnvironmentImpactIT = '';
    primaryColor: any;
    mandatoryFields: any = [];
    touchImage: any = '';
    currentLang: any = '';
    settingsFieldsList = [
        {index: 0, name: 'You are a?', id: 'userTypeId', isRequired: false, check: false, alternativeName: ''},
        {index: 1, name: 'Reason for visit', id: 'note', isRequired: false, check: false, alternativeName: ''},
        {index: 2, name: 'Name', id: 'visitorPersonName', isRequired: false, check: false, alternativeName: ''},
        {index: 3, name: 'Surname', id: 'visitorSurname', isRequired: false, check: false, alternativeName: ''},
        {index: 4, name: 'Identity Card', id: 'passportId', isRequired: false, check: false, alternativeName: ''},
        {index: 5, name: 'Internal contact person', id: 'visitorInternalRef', isRequired: false, check: false, alternativeName: ''},
        {index: 6, name: 'Email', id: 'visitorEmail', isRequired: false, check: false, alternativeName: ''},
        {index: 7, name: 'Telephone', id: 'visitorPhone', isRequired: false, check: false, alternativeName: ''},
        {index: 8, name: 'Company', id: 'visitorName', isRequired: false, check: false, alternativeName: ''},
        {index: 9, name: 'Means of transport', id: 'meansOfTransport', isRequired: false, check: false, alternativeName: ''},
        {index: 10, name: 'Km (A/R)', id: 'km', isRequired: false, check: false, alternativeName: ''},
        {index: 11, name: 'Departure from',id: 'departureFrom', isRequired: false, check: false ,alternativeName: ''},
        {index: 12, name: 'Arrive at',id: 'arriveAt', isRequired: false, check: false, alternativeName: ''},
    ];

    constructor(
        private formBuilder: FormBuilder,
        private visitorAPIService: FeVisitorService,
        public route: ActivatedRoute,
        public translate: TranslateService,
        private settingManager: SettingsManagementService,
        private languageService: LanguageService,
    ) {
        this.userId = this.route.snapshot.paramMap.get('userId');
        this.siteKey = this.route.snapshot.paramMap.get('siteKey');
        /*this.currentLang = JSON.parse(localStorage.getItem('visitor-module'))?.currentLanguage;*/
        this.languageService.getCurrentLanguage().subscribe((lang) => {
            this.currentLang = lang;
        });
        this.visitorForm = this.formBuilder.group({
            userId: this.userId,
            siteKey: this.siteKey,
            userTypeId: [''],
            userType: [''],
            visitorPersonName: ['', [FeVisitorService.noWhitespaceValidator]],
            visitorSurname: ['', [FeVisitorService.noWhitespaceValidator]],
            passportId: ['', [FeVisitorService.noWhitespaceValidator]],
            visitorInternalRef: ['', [FeVisitorService.noWhitespaceValidator]],
            visitorEmail: ['', [FeVisitorService.noWhitespaceValidator, Validators.pattern('^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([A-Za-z]{2,6}(?:\\.[A-Za-z]{2,6})?)$')]],
            visitorPhone: ['', [FeVisitorService.noWhitespaceValidator]],
            visitorName: ['', [FeVisitorService.noWhitespaceValidator]],
            privacyPolicy: [false],
            note: [''],
            startArea: [''],
            destArea: [''],
            transportTracks: [[]],
            visitorCompany: [''],
            meansOfTransport: [''],
            departureFrom: [''],
            arriveAt: [''],
            km: ['']
        });
    }

    async ngOnInit(): Promise<void> {
        this.visitorForm.get('privacyPolicy').setValue(false);
        await this.getVisitorDetails();
    }

    async getVisitorDetails(): Promise<any> {
        this.visitorAPIService.toggleLoaderVisibility(true);
        const data = await this.visitorAPIService.getVisitorDetail();
        if (data.result) {
            this.visitorAPIService.toggleLoaderVisibility(false);
            this.category = data.category;
            this.transportNotes = data.transportNotes;
            this.transportTypes = data.transportTypes;
        } else {
            this.visitorAPIService.toggleLoaderVisibility(false);
        }

        try {
            const res = await this.visitorAPIService.getVisitorData();
            if (res.result) {
                this.welcomeMessageEN = res.data?.welcomeMessageEN;
                this.welcomeMessageIT = res.data?.welcomeMessageIT;
                this.primaryColor = res.data?.brandColor;
                this.touchImage = res.data?.touchPointImage;
                this.impactTextEN = res.data?.visitorImpactEN;
                this.impactTextIT = res.data?.visitorImpactIT;
                this.visitorEnvironmentImpactEN = res.data?.visitorEnvironmentImpactEN;
                this.visitorEnvironmentImpactIT = res.data?.visitorEnvironmentImpactIT;
                this.settingManager.setSettingsValue('primaryColor', this.primaryColor);
                this.mandatoryFields = res?.data?.mandatoryFields.length !== 0 ? res.data?.mandatoryFields : this.settingsFieldsList;
                for (const field of this.mandatoryFields) {
                    const formControl = this.visitorForm.get(field.id);
                    if (formControl && field.check && field.isRequired) {
                        formControl.setValidators([Validators.required]);
                    } else if (formControl) {
                        formControl.clearValidators();
                    }
                    formControl.updateValueAndValidity();
                }
                this.visitorAPIService.toggleLoaderVisibility(false);
            } else {
                swal.fire('', this.translate.instant(res.reason), 'info');
                this.visitorAPIService.toggleLoaderVisibility(false);
            }
        } catch (error) {
            swal.fire('', this.translate.instant(error.message), 'info');
            this.visitorAPIService.toggleLoaderVisibility(false);
        }
    }


    getReason() {
        const categoryData = this.category.find(category => category.id === this.selectedUserType);
        this.visitorForm.patchValue({userType: categoryData.name});
        this.reasonsForVisit = this.transportNotes.filter(note => note.userCategories.includes(this.selectedUserType));
    }

    isFieldRequired(fieldId: string): boolean {
        const field = this.mandatoryFields.find(item => item.id === fieldId);
        return field ? field.isRequired : false;
    }

    isFieldChecked(fieldId: string): boolean {
        const field = this.mandatoryFields.find(item => item.id === fieldId);
        return field ? field.check : false;
    }

    getAlternativeName(fieldId: string): string {
        const field = this.mandatoryFields.find(item => item.id === fieldId);
        const alternativeName = field?.alternativeName || field?.name;
        return alternativeName;
    }

    getCommon = (left, right, compareFunction) =>
        left.filter(leftValue =>
            right.some(rightValue =>
                compareFunction(leftValue, rightValue)))

    isSameUserArray = (a: any, b: any) => a.id === b.id;

    onlyInLeft(left, right, compareFunction): any {
        if (!right || right.length === 0) {
            return left;
        }

        return left?.filter(leftValue =>
            !right.some(rightValue =>
                compareFunction(leftValue, rightValue)
            )
        );
    }

    numberOnly(event: { which: any; keyCode: any; }): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    isAddTrackEnabled() {
        return this.meansOfTransport != null && this.distance > 0;
    }

    isConfirmEnabled() {
        const isRequiredMeansOfTransport = this.isFieldChecked('meansOfTransport') && this.isFieldRequired('meansOfTransport');
        const isRequiredKM = this.isFieldChecked('km') && this.isFieldRequired('km');
        if (isRequiredMeansOfTransport && isRequiredKM) {
            if (this.visitorForm.value.transportTracks.length > 0){
                return this.visitorForm.valid && this.visitorForm.value.privacyPolicy;
            }else{
                return this.visitorForm.valid && this.visitorForm.value.transportTracks.length > 0 && this.visitorForm.value.privacyPolicy;
            }
        } else {
            return this.visitorForm.valid && this.visitorForm.value.privacyPolicy;
        }
    }

    onSubmit() {
        if (this.privacyPolicy) {
            this.visitorAPIService.toggleLoaderVisibility(true);
            this.visitorForm.patchValue({
                visitorCompany: this.visitorForm.value.visitorName
            });
            //if (this.visitorForm.value.transportTracks.length > 0) {
                /*this.visitorForm.removeControl('km');
                this.visitorForm.removeControl('meansOfTransport');*/
                this.visitorAPIService.addVisitor(this.visitorForm.value).then((res) => {
                    if (res.result) {
                        this.visitorAPIService.toggleLoaderVisibility(false);
                        let kgCO2;
                        for (const path of this.pathData) {
                            kgCO2 = path.transportType.co2emission * path.distance;
                            this.totalkgCO2 = this.totalkgCO2 + kgCO2;
                            this.totalKM = this.totalKM + path.distance;
                        }
                        this.successMessage = true;
                    } else {
                        this.visitorAPIService.toggleLoaderVisibility(false);
                        swal.fire(
                            '',
                            this.translate.instant(res.reason),
                            'info'
                        );
                        /*swal.fire(
                            '',
                            'Visitatore già registrato',
                            'info'
                        );*/
                    }
                });
            //}
        }
    }

    addVisitorFunction() {
        this.visitorAPIService.addVisitor(this.visitorForm.value).then((res) => {
            if (res.result) {
                this.visitorAPIService.toggleLoaderVisibility(false);
                let kgCO2;
                for (const path of this.pathData) {
                    kgCO2 = path.transportType.co2emission * path.distance;
                    this.totalkgCO2 = this.totalkgCO2 + kgCO2;
                    this.totalKM = this.totalKM + path.distance;
                }
                this.successMessage = true;
            } else {
                this.visitorAPIService.toggleLoaderVisibility(false);
                swal.fire(
                    '',
                    'Visitatore già registrato',
                    'info'
                );
            }
        });
    }

    pathAdd() {
        if (this.meansOfTransport && this.distance) {
            this.pathObj = {
                transportType: this.meansOfTransport,
                distance: this.distance
            };
            this.pathData.push(this.pathObj);
            this.meansOfTransport = '';
            this.distance = null;
        }

        this.visitorForm.patchValue({
            transportTracks: this.pathData,
        });
        this.checkTransport();
    }

    deletePath(index: any) {
        this.pathData.splice(index, 1);
        this.visitorForm.patchValue({
            transportTracks: this.pathData,
        });
        this.checkTransport();
    }

    space(event: any): void {
        if (event.target.selectionStart === 0 && event.code === 'Space') {
            event.preventDefault();
        }
    }

    formatNumber(value: number): string {
        const formattedValue = value.toFixed(2).toString().replace('.', ',');
        return formattedValue;
    }

    checkTransport(): void {
        const isRequiredMeansOfTransport = this.isFieldChecked('meansOfTransport') && this.isFieldRequired('meansOfTransport');
        const isRequiredKM = this.isFieldChecked('km') && this.isFieldRequired('km');

        if (isRequiredMeansOfTransport && isRequiredKM) {
            if (this.visitorForm.value.transportTracks.length > 0) {
                this.visitorForm.get('meansOfTransport').clearValidators();
                this.visitorForm.get('km').clearValidators();
                this.visitorForm.updateValueAndValidity();
            } else {
                this.visitorForm.get('meansOfTransport').setValidators([Validators.required]);
                this.visitorForm.get('km').setValidators([Validators.required]);
                this.visitorForm.updateValueAndValidity();
            }
        }else {
            this.visitorForm.get('meansOfTransport').clearValidators();
            this.visitorForm.get('km').clearValidators();
            this.visitorForm.updateValueAndValidity();
        }
    }
}
