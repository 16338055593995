import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {WelcomeComponent} from './components/welcome/welcome.component';
import {PrivacyPolicyComponent} from './components/privacy-policy/privacy-policy.component';

const routes: Routes = [
    {path: ':siteKey/:userId', component: WelcomeComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
