import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {FormControl} from "@angular/forms";
import {EncryptionService} from "./encryption.service";

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    public static authUser: any = {};
    public static loginUserData: any = {};
    public static loginUserID = '';
    public static loginUserToken = '';
    public static companyReferenceEmail = '';
    static languageData: string;
    languageTranslatorChange: Subject<string> = new Subject<string>();
    loaderVisibilityChange: Subject<boolean> = new Subject<boolean>();
    setLanguageTranslator: string = 'en';
    isLoaderVisible = false;
    public lang: string;
    public static encryptionService:EncryptionService = new EncryptionService();
    constructor(public translate: TranslateService,) {
        translate.addLangs(['en', 'it']);
        translate.setDefaultLang('it');
    }

    public static getLanguageName() {

        let languageData = localStorage.getItem('currentLanguage') || "it";
        if (languageData) {
            return languageData;
        }
        return null;
    }

    static noWhitespaceValidator(control: FormControl): any {
        const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : {'required': true};
    }

    public static onLogOut(): void {
        localStorage.removeItem('NPToken');
        localStorage.removeItem('NPLoggedInUser');
        localStorage.removeItem('NPLoginId');
        HelperService.loginUserData = null;
        HelperService.loginUserID = null;
        HelperService.loginUserToken = null;
    }

    public static saveLocalStorage(loginUser): void {
        HelperService.loginUserData = loginUser;
        HelperService.loginUserID = loginUser?.id;
        HelperService.loginUserToken = loginUser?.token;
        localStorage.removeItem('NPToken');
        localStorage.removeItem('NPLoggedInUser');
        localStorage.removeItem('NPLoginId');
        this.encryptionService.setDataToLocalStorage('NPLoggedInUser', JSON.stringify(loginUser));
        this.encryptionService.setDataToLocalStorage('NPLoginId', HelperService.loginUserID);
        this.encryptionService.setDataToLocalStorage('NPToken', HelperService.loginUserToken);
    }

    public static getLocalStorage(): void {
        const loginUser: any = this.encryptionService.getDataFromLocalStorage('NPLoggedInUser');
        if (loginUser) {
            HelperService.loginUserData = loginUser;
            HelperService.loginUserID = loginUser?.id;
            HelperService.loginUserToken = loginUser?.token;
        }
    }

    languageTranslator(isSidebarVisible: string): void {
        this.setLanguageTranslator = isSidebarVisible;
        this.languageTranslatorChange.next(this.setLanguageTranslator);
    }

    toggleLoaderVisibility(isSidebarVisible: boolean): void {
        this.isLoaderVisible = isSidebarVisible;
        this.loaderVisibilityChange.next(this.isLoaderVisible);
    }

}
