<div *ngIf="!successMessage" class="formContainer">
    <app-header style="width: 100%;"></app-header>
    <div class="form-wrap-content">
        <div class="title">
            <h2 *ngIf="currentLang === 'en'">{{welcomeMessageEN}}</h2>
            <h2 *ngIf="currentLang === 'it'">{{welcomeMessageIT}}</h2>
        </div>
        <form (ngSubmit)="onSubmit()" [formGroup]="visitorForm" class="form-wrapper">
            <div *ngIf="isFieldChecked('userTypeId')" class="form-field">
                <mat-form-field appearance="outline" class="element-full-width">
                    <!--<mat-label>{{'You are a?' | translate}}<span style="color:#EA5455">*</span></mat-label>-->
                    <mat-label>{{getAlternativeName('userTypeId') | translate}}<span *ngIf="isFieldRequired('userTypeId')" style="color: #EA5455">*</span></mat-label>
                    <mat-select (selectionChange)="getReason()" [(ngModel)]="selectedUserType" formControlName="userTypeId">
                        <mat-option *ngFor="let category of category" [value]="category.id">
                            <span class="d-flex align-items-center">{{ category.name }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="isFieldChecked('note')" class="form-field">
                <mat-form-field appearance="outline" class="element-full-width">
                    <mat-label>{{getAlternativeName('note') | translate}}<span *ngIf="isFieldRequired('note')" style="color: #EA5455">*</span>
                    </mat-label>
                    <mat-select formControlName="note" name="note">
                        <mat-option *ngFor="let reason of reasonsForVisit" [value]="reason.description">
                            <span class="d-flex align-items-center">{{ reason.description }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="isFieldChecked('visitorPersonName')" class="form-field">
                <mat-form-field appearance="outline" class="element-full-width">
                    <mat-label>{{getAlternativeName('visitorPersonName') | translate}}<span *ngIf="isFieldRequired('visitorPersonName')" style="color: #EA5455">*</span></mat-label>
                    <input (keydown)="space($event)" formControlName="visitorPersonName" matInput name="visitorPersonName" type="text">
                </mat-form-field>
            </div>
            <div *ngIf="isFieldChecked('visitorSurname')" class="form-field">
                <mat-form-field appearance="outline" class="element-full-width">
                    <mat-label>{{getAlternativeName('visitorSurname') | translate}}<span *ngIf="isFieldRequired('visitorSurname')" style="color: #EA5455">*</span></mat-label>
                    <input (keydown)="space($event)" formControlName="visitorSurname" matInput name="visitorSurname" type="text">
                </mat-form-field>
            </div>
            <div *ngIf="isFieldChecked('passportId')" class="form-field">
                <mat-form-field appearance="outline" class="element-full-width">
                    <mat-label>{{getAlternativeName('passportId') | translate}}<span *ngIf="isFieldRequired('passportId')" style="color: #EA5455">*</span></mat-label>
                    <input (keydown)="space($event)" formControlName="passportId" matInput name="passportId" type="text">
                </mat-form-field>
            </div>
            <div *ngIf="isFieldChecked('visitorInternalRef')" class="form-field">
                <mat-form-field appearance="outline" class="element-full-width">
                    <mat-label>{{getAlternativeName('visitorInternalRef') | translate}}<span *ngIf="isFieldRequired('visitorInternalRef')" style="color: #EA5455">*</span></mat-label>
                    <input (keydown)="space($event)" formControlName="visitorInternalRef" matInput name="visitorInternalRef" type="text">
                </mat-form-field>
            </div>
            <div *ngIf="isFieldChecked('visitorEmail')" class="form-field">
                <mat-form-field appearance="outline" class="element-full-width">
                    <mat-label>{{getAlternativeName('visitorEmail') | translate}}<span *ngIf="isFieldRequired('visitorEmail')" style="color: #EA5455">*</span></mat-label>
                    <input (keydown)="space($event)" formControlName="visitorEmail" matInput name="visitorEmail" type="text">
                </mat-form-field>
            </div>
            <div *ngIf="isFieldChecked('visitorPhone')" class="form-field">
                <mat-form-field appearance="outline" class="element-full-width">
                    <mat-label>{{getAlternativeName('visitorPhone') | translate}}<span *ngIf="isFieldRequired('visitorPhone')" style="color: #EA5455">*</span></mat-label>
                    <input (keydown)="space($event)" (keypress)="numberOnly($event)" formControlName="visitorPhone" matInput name="visitorPhone" type="text">
                </mat-form-field>
            </div>
            <div *ngIf="isFieldChecked('visitorName')" class="form-field">
                <mat-form-field appearance="outline" class="element-full-width">
                    <mat-label>{{getAlternativeName('visitorName') | translate}}<span *ngIf="isFieldRequired('visitorName')" style="color: #EA5455">*</span></mat-label>
                    <input (keydown)="space($event)" formControlName="visitorName" matInput name="visitorName" type="text">
                </mat-form-field>
            </div>
            <div class="form-field" style="padding-bottom: 12px;">
                <mat-checkbox [(ngModel)]="privacyPolicy" formControlName="privacyPolicy">
                    <div class="checkbox"><a href="#" routerLink="/privacy-policy" target="_blank">{{'I have read the privacy information' | translate}}</a></div>
                </mat-checkbox>
            </div>
            <div *ngIf="isFieldChecked('departureFrom')" class="form-field">
                <mat-form-field appearance="outline" class="element-full-width">
                    <mat-label>{{getAlternativeName('departureFrom') | translate}}<span *ngIf="isFieldRequired('departureFrom')" style="color: #EA5455">*</span></mat-label>
                    <input (keydown)="space($event)" formControlName="departureFrom" matInput name="departureFrom" type="text">
                </mat-form-field>
            </div>
            <div *ngIf="isFieldChecked('arriveAt')" class="form-field">
                <mat-form-field appearance="outline" class="element-full-width">
                    <mat-label>{{getAlternativeName('arriveAt') | translate}}<span *ngIf="isFieldRequired('arriveAt')" style="color: #EA5455">*</span></mat-label>
                    <input (keydown)="space($event)" formControlName="arriveAt" matInput name="arriveAt" type="text">
                </mat-form-field>
            </div>
            <div *ngIf="isFieldChecked('meansOfTransport') && isFieldChecked('km')" class="custom-row">
                <div class="custom-col-8">
                    <div class="form-field">
                        <mat-form-field appearance="outline" class="element-full-width">
                            <mat-label>{{getAlternativeName('meansOfTransport') | translate}}<span *ngIf="isFieldRequired('meansOfTransport') && visitorForm.value?.transportTracks.length == 0" style="color: #EA5455">*</span></mat-label>
                            <mat-select [(ngModel)]="meansOfTransport" formControlName="meansOfTransport" name="meansOfTransport">
                                <mat-option *ngFor="let type of transportTypes" [value]="type">
                                    <span class="d-flex align-items-center">{{ type.name }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="custom-col-4">
                    <div  class="form-field">
                        <mat-form-field appearance="outline" class="element-full-width">
                            <mat-label>{{getAlternativeName('km') | translate}}<span *ngIf="isFieldRequired('km') && visitorForm.value?.transportTracks.length == 0" style="color: #EA5455">*</span></mat-label>
                            <input (keydown)="space($event)" [(ngModel)]="distance" formControlName="km" matInput min="1" name="km"  type="number">
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-field">
                    <button (click)="pathAdd()" [disabled]="!isAddTrackEnabled()" class="primary-bttn" type="button">{{'Add path' | translate}}</button>
                </div>
            </div>

            <div *ngIf="isFieldChecked('meansOfTransport') && isFieldChecked('km')" class="user-tracks">
                <div *ngIf="pathData.length > 0" class="timeline">
                    <div *ngFor="let path of pathData; let i = index" class="timeline-item">
                        <div class="indicators">
                            <div class="line"></div>
                            <div class="bullet"></div>
                            <div class="line"></div>
                        </div>
                        <div class="card">
                            <div class="content">
                                <h3>{{ path.transportType.name }}</h3>
                                <span>{{ path.distance }} Km</span>
                            </div>
                            <div class="action">
                                <button (click)="deletePath(i)" mat-icon-button matTooltip="Delete">
                                    <i class="material-icons" style="color: red">delete</i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <p *ngIf="pathData.length === 0">{{'The means of transport have not yet been entered.' | translate}}</p>
            </div>
            <div class="form-field">
                <button [disabled]="!isConfirmEnabled()" class="primary-bttn" type="submit">{{'Save' | translate}}</button>
            </div>
        </form>
    </div>
</div>
<div *ngIf="successMessage" class="formContainer welcome-wrap">
    <app-header style="width: 100%;"></app-header>
    <div class="form-wrap-content">
        <!--<img class="done-image" src="./assets/images/agos4Good.svg">-->
        <!--<img [src]="touchImage ? touchImage : './assets/images/agos4Good.svg'" class="done-image">-->
        <img *ngIf="touchImage !== '' && touchImage !== null" [src]="touchImage " class="done-image">
        <!--<p>{{'SuccessMessage' | translate}}</p>-->
        <p *ngIf="currentLang === 'en'">{{impactTextEN}}</p>
        <p *ngIf="currentLang === 'it'">{{impactTextIT}}</p>
        <mat-card class="mat-card mat-focus-indicator">
        <div class="chart-body">
            <div class="info-container">
                <!--<p style="width:100%;font-weight:550;">{{'Your environmental impact'| translate}}</p>-->
                <p *ngIf="currentLang === 'en'" style="width:100%;font-weight:550;">{{visitorEnvironmentImpactEN}}</p>
                <p *ngIf="currentLang === 'it'" style="width:100%;font-weight:550;">{{visitorEnvironmentImpactIT}}</p>
                <div class="info-tile">
                    <mat-label class="info-header">kgCO2</mat-label>
                    <mat-label class="info-counter">{{ formatNumber(totalkgCO2) }}</mat-label>
                </div>
                <div class="info-tile">
                    <mat-label class="info-header">KM</mat-label>
                    <mat-label class="info-counter">{{ totalKM }}</mat-label>
                </div>
            </div>
        </div>
        </mat-card>
    </div>
</div>
