<div class="header">
    <img alt="Logo" class="agos-logo" src="assets/images/logo-white.svg"/>
    <div class="language-menu-new">
        <mat-form-field >
            <mat-select (selectionChange)='onLanguageChange($event)' [(ngModel)]="userLanguageSelect" name='language'>
                <mat-select-trigger>
                  <span (click)="enLocale()" *ngIf="userLanguageSelect === 'en'" class="lang-flag" >
                    <img  [src]="englishFlag" alt=""><!-- ENGLISH-->
                  </span>
                    <span (click)="itLocale()" *ngIf="userLanguageSelect === 'it'" class="lang-flag" >
                    <img  [src]="italianFlag" alt=""><!--ITALIANO-->
                  </span>
                </mat-select-trigger>
                <ng-container *ngFor="let lang of language">
                    <mat-option *ngIf="lang.checked" [value]="lang.languageCode" style="text-transform: uppercase">
                        <img [src]="lang.languagesFlagImg" alt=""> {{lang.languages}}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>
</div>
