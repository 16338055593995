import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {WelcomeComponent} from './components/welcome/welcome.component';
import {MaterialModule} from './material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {DatePipe, registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';
import {DefaultDataServiceConfig} from '@ngrx/data';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter} from '@angular/material/core';
import {environment} from '../environments/environment';
import {FeCommonModule} from '../../../fe-common-v2/src/lib';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {HeaderComponent} from './components/header/header.component';
import {visitorHelper} from './service/visitorHelper.service';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
registerLocaleData(localeIt);

export class MyDateAdapter extends NativeDateAdapter {
    getFirstDayOfWeek(): number {
        return 1;
    }
}

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    },
};

const defaultDataServiceConfig: DefaultDataServiceConfig = {
    root: environment.api_host + '{api_prefix}',
    timeout: 20000,
};

@NgModule({
    declarations: [
        AppComponent,
        WelcomeComponent,
        HeaderComponent,
        PrivacyPolicyComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        PdfViewerModule,
        FeCommonModule.forRoot({env: environment}),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    providers: [
        visitorHelper,
        DatePipe,
        {provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig},
        {
            provide: LOCALE_ID,
            useValue: 'en'
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'en'
        },
        {
            provide: DateAdapter,
            useClass: MyDateAdapter
        },
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/');
}
