import {Injectable} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {EncryptionService} from "./encryption.service";

@Injectable({
    providedIn: 'root'
})
export class visitorHelper {
    public static encryptionService:EncryptionService= new EncryptionService()
    public static dynamicData: any = {
        language: JSON.parse(visitorHelper.encryptionService.getDataFromLocalStorage('visitor-module'))?.currentLanguage
    };
    languageTranslatorChange: Subject<string> = new Subject<string>();
    loaderVisibilityChange: Subject<boolean> = new Subject<boolean>();
    setLanguageTranslator = 'it';
    languageList = new Subject<any>();
    isLoaderVisible = false;


    public static getLanguageName() {
        const websiteDate = JSON.parse(visitorHelper.encryptionService.getDataFromLocalStorage('visitor-module'));
        let languageData = websiteDate?.currentLanguage;
        if (languageData) {
            return languageData;
        }
        return null;
    }

    static noWhitespaceValidator(control: FormControl): any {
        const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : {'required': true};
    }

    languageTranslator(isSidebarVisible: string): void {
        this.setLanguageTranslator = isSidebarVisible;
        this.languageTranslatorChange.next(this.setLanguageTranslator);
    }

    setLocalStorage() {
        const visitorModuleData = JSON.parse(visitorHelper.encryptionService.getDataFromLocalStorage('visitor-module')) || {};
        visitorModuleData.currentLanguage =  visitorModuleData.currentLanguage || null;
        visitorModuleData.primaryColor = null;
        visitorModuleData.secondaryColor = null;
        // Store the updated 'visitor-module' object back in localStorage
        visitorHelper.encryptionService.setDataToLocalStorage('visitor-module', JSON.stringify(visitorModuleData));
    }

    toggleLoaderVisibility(isSidebarVisible: boolean): void {
        this.isLoaderVisible = isSidebarVisible;
        this.loaderVisibilityChange.next(this.isLoaderVisible);
    }

    saveLocalStorage(loginUser: any): void {
        const visitorModuleData = JSON.parse(visitorHelper.encryptionService.getDataFromLocalStorage('visitor-module'));
        visitorModuleData.currentLanguage = null;
        visitorHelper.encryptionService.setDataToLocalStorage('visitor-module', JSON.stringify(visitorModuleData));

        visitorModuleData.currentLanguage = visitorHelper.dynamicData.language;
        visitorHelper.encryptionService.setDataToLocalStorage('visitor-module', JSON.stringify(visitorModuleData));
    }

    public isEmpty(obj): boolean {
        return typeof obj === 'object' && Object.keys(obj).length === 0;
    }

    setThemeColor(primaryColor: string, secondaryColor: string, thirdColorOption: string): void {
        document.documentElement.style.setProperty('--primary-color', primaryColor);
        document.documentElement.style.setProperty('--secondary-color', secondaryColor);
        document.documentElement.style.setProperty('--third-color-option', thirdColorOption);

        const visitorModuleData = JSON.parse(visitorHelper.encryptionService.getDataFromLocalStorage('visitor-module'));
        visitorModuleData.primaryColor =  primaryColor;
        visitorModuleData.secondaryColor =  secondaryColor;
        visitorHelper.encryptionService.setDataToLocalStorage('visitor-module', JSON.stringify(visitorModuleData));
    }

    getVisitorStorage(key: string) {
        const visitorModuleData = JSON.parse(visitorHelper.encryptionService.getDataFromLocalStorage('visitor-module')) || {};
        return visitorModuleData[key] || null;
    }

    setVisitorStorage(key: string, value: string) {
        const visitorModuleData = JSON.parse(visitorHelper.encryptionService.getDataFromLocalStorage('visitor-module')) || {};
        visitorModuleData[key] =  value;
        visitorHelper.encryptionService.setDataToLocalStorage('visitor-module', JSON.stringify(visitorModuleData));
    }

    removeVisitorStorage(key: string) {
        const visitorModuleData = JSON.parse(visitorHelper.encryptionService.getDataFromLocalStorage('visitor-module')) || {};
        visitorModuleData[key] =  null;
        visitorHelper.encryptionService.setDataToLocalStorage('visitor-module', JSON.stringify(visitorModuleData));
    }
}
